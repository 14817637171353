import { Button, Form } from 'antd';
import {
  CheckOutlined, EditOutlined, UserAddOutlined, UserDeleteOutlined, UndoOutlined
} from '@ant-design/icons';
import type { ComponentProps } from 'react';
import { useState } from 'react';

import { ActionsArea, BackButton, NextButton } from '../../../../components/buttons';
import {
  FormItemAdresse,
  FormItemBatiment,
  FormItemCodePostal,
  FormItemCodeTypeTiers,
  FormItemCodeTypeVoie,
  FormItemCommune,
  FormItemEtage,
  FormItemGSM,
  FormItemHopital,
  FormItemInterphone,
  FormItemNom,
  FormItemNumeroVoie,
  FormItemPorte,
  FormItemPrenom,
  FormItemProfessionnelLiberal,
  FormItemService,
  FormItemTelephoneFixe,
  FormItemRaisonSuppression,
} from '../../../../components/formItems';
import type { Patient, Referent } from '../../../../interfaces/ContratPret';
import ContentCard from '../../../../components/ContentCard';
import Drawer from '../../../../components/Drawer';

function AddButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      icon={<UserAddOutlined />}
      shape="round"
      size="large"
      style={{ backgroundColor: '#f0d80f', border: 'none', boxShadow: '#044493 0px 5px 5px', color: '#044493' }}
      type="primary"
    >
      Ajouter un référent médical
    </Button>
  );
}

function EditButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      icon={<EditOutlined />}
      shape="round"
      style={{ backgroundColor: '#f0d80f', border: 'none', color: '#044493', width: 'calc(50% - 10px)' }}
      type="primary"
    >
      Modifier
    </Button>
  );
}

function ValidateButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      icon={<CheckOutlined />}
      shape="round"
      style={{ backgroundColor: '#f0d80f', border: 'none', color: '#044493', width: 'calc(50% - 10px)' }}
      type="primary"
    >
      Valider
    </Button>
  );
}

function CancelButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      danger
      icon={<UndoOutlined />}
      shape="round"
      style={{ width: 'calc(50% - 10px)' }}
      type="primary"
    >
      Annuler
    </Button>
  );
}

function RemoveButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      danger
      icon={<UserDeleteOutlined />}
      shape="round"
      style={{ width: 'calc(50% - 10px)' }}
      type="primary"
    >
      Supprimer
    </Button>
  );
}

function RestoreButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      icon={<UserAddOutlined />}
      shape="round"
      style={{ backgroundColor: '#f0d80f', border: 'none', color: '#044493', width: '100%' }}
      type="primary"
    >
      Restaurer
    </Button>
  );
}

function SaveButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      icon={<CheckOutlined />}
      shape="round"
      style={{ backgroundColor: '#f0d80f', border: 'none', color: '#044493', width: '100%' }}
      type="primary"
    >
      Sauvegarder
    </Button>
  );
}

interface FormReferentProps {
  onSubmit: (referent: Referent) => void;
  referent: Referent;
}

function FormReferent({ onSubmit, referent }: FormReferentProps) {
  const handleSubmit = ({ sTelFixe, sTelGsm, ...rest }: Referent) => onSubmit({
    ...rest,
    sTelFixe: sTelFixe?.replace(/\s/g, ''),
    sTelGsm: sTelGsm?.replace(/\s/g, '')
  });

  const [isAux, setIsAux] = useState(false);

  return (
    <Form name="formReferent" onFinish={handleSubmit}>
      <FormItemCodeTypeTiers
        autoFocus
        errorMessage="Veuillez sélectionner un type de référent médical"
        initialValue={referent?.sTypeTiers}
        onSelect={(item: any, option: any) => {
          if (option.key === 'AUX') {
            setIsAux(true);
          } else {
            setIsAux(false);
          }
        }}
        label="Type de référent"
      />

      {!isAux && <FormItemProfessionnelLiberal
        disabled={referent?.bSupprime}
        initialValue={referent?.bLiberal}
      />}


      <FormItemNom
        errorMessage="Veuillez saisir le nom du référent médical"
        initialValue={referent?.sNom}
      />

      <FormItemPrenom
        errorMessage="Veuillez saisir le prénom du référent médical"
        initialValue={referent?.sPrenom}
        required={false}
      />

      <FormItemHopital initialValue={referent?.sHopital} />

      <FormItemService initialValue={referent?.sService} />

      <div style={{ alignItems: 'start', display: 'grid', gridGap: '20px', gridTemplateColumns: 'repeat(2, 1fr)' }}>
        <FormItemNumeroVoie initialValue={referent?.sNumeroVoie} />
        <FormItemCodeTypeVoie initialValue={referent?.sCodeTypeVoie} />
      </div>

      <FormItemAdresse initialValue={referent?.sAdresse} />

      <div style={{ display: 'grid', gridColumnGap: '20px', gridTemplateColumns: 'repeat(2, 1fr)' }}>
        <FormItemBatiment initialValue={referent?.sBatiment} />

        <FormItemEtage initialValue={referent?.sEtage} />

        <FormItemPorte initialValue={referent?.sPorte} />

        <FormItemInterphone initialValue={referent?.sInterphone} />
      </div>

      <FormItemCodePostal initialValue={referent?.sCodePostal} />

      <FormItemCommune
        errorMessage="Veuillez saisir la commune du référent médical"
        initialValue={referent?.sVille}
      />

      <FormItemGSM initialValue={referent?.sTelGsm} />

      <FormItemTelephoneFixe initialValue={referent?.sTelFixe} />

      <Form.Item style={{ margin: '20px 0' }}>
        <SaveButton htmlType="submit" />
      </Form.Item>
    </Form >
  );
}

interface ReferentItemProps {
  index: number;
  onEditClick: () => void;
  handleReferentsUpdate: Function;
  referent: Referent;
}

function ReferentItem({ index, onEditClick, handleReferentsUpdate, referent }: ReferentItemProps) {
  const [sRaisonSuppresion, setsRaisonSuppression] = useState(referent.sRaisonSuppression);
  const handleClick = () => handleReferentsUpdate(index, { bSupprime: !referent.bSupprime, sRaisonSuppression: undefined });
  const handleChange = (e: any) => {
    setsRaisonSuppression(e.target.value);
  }
  const handleSubmit = (referent: Referent) => {
    handleReferentsUpdate(index, { sRaisonSuppression: referent.sRaisonSuppression });
  };

  return (
    <ContentCard dark={referent?.bSupprime} style={{ marginBottom: '20px' }}>
      <div style={{ marginBottom: '15px', textAlign: 'center' }}>
        {`${referent.sNom?.toUpperCase()} ${referent.sPrenom ? `${referent.sPrenom} ` : ''}`}
        <span style={{ fontSize: 'smaller' }}>
          {`(${referent.sPKTypeTiers === 'MED' && referent.sSpecialites ? referent.sSpecialites?.toLowerCase() : referent.sTypeTiers?.toLowerCase()})`} {referent.bMedecinTraitant ? <b>MT</b> : ''}
        </span>
      </div>

      {referent.bSupprime
        ? <>
          <Form name="formReferentSuppression" onFinish={handleSubmit}>
            <FormItemRaisonSuppression onChange={handleChange} initialValue={sRaisonSuppresion} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <CancelButton onClick={handleClick} />
              <ValidateButton disabled={sRaisonSuppresion === referent.sRaisonSuppression} htmlType="submit" />
            </div>
          </Form>
        </>
        : <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <RemoveButton onClick={handleClick} />
          <EditButton onClick={onEditClick} />
        </div>
      }
    </ ContentCard >
  );
}

interface SectionReferentsProps {
  onPreviousClick: () => void;
  onSubmit: ({ patient }: { patient: Patient }) => void;
  patient: Patient;
}

export default function SectionReferents({ onPreviousClick, onSubmit, patient }: SectionReferentsProps) {
  const [referentIndexBeingEdited, setReferentIndexBeingEdited] = useState<number>();
  const [referents, setReferents] = useState(patient.referents);

  const closeDrawer = () => setReferentIndexBeingEdited(undefined);

  const handleReferentsUpdate = (referentIndex: number, updatedProcheData: Partial<Referent>) => {
    setReferents(referents => {
      referents.splice(referentIndex, 1, { ...referents[referentIndex], ...updatedProcheData })
      const sortedReferents = referents.sort((a: any, b: any) => {
        if (a.sFKTypeTiers > b.sFKTypeTiers) return 1;
        if (a.sFKTypeTiers < b.sFKTypeTiers) return -1;
        if (a.sNom > b.sNom) return 1;
        if (a.sNom < b.sNom) return -1;
        return 0;
      });

      return [...sortedReferents];
    });
  };

  const handleSubmit = () => onSubmit({
    patient: { ...patient, referents: referents.filter(r => 'iPKAnnuaire' in r || !r.bSupprime) }
  });

  return (
    <>
      <div style={{ height: '100%', overflowY: 'scroll', scrollbarWidth: 'none', width: '100%' }}>
        <Drawer
          bodyStyle={{ backgroundColor: '#edf3f4' }}
          destroyOnClose
          footerStyle={{ backgroundColor: '#edf3f4' }}
          headerStyle={{ backgroundColor: '#edf3f4' }}
          onClose={closeDrawer}
          placement="right"
          title={referentIndexBeingEdited && referentIndexBeingEdited > referents.length
            ? 'Ajout d\'un référent' : 'Mise à jour d\'un référent'
          }
          visible={referentIndexBeingEdited !== undefined}
        >
          {referentIndexBeingEdited !== undefined && <FormReferent
            onSubmit={updatedReferentData => { handleReferentsUpdate(referentIndexBeingEdited, updatedReferentData); closeDrawer(); }}
            referent={referents[referentIndexBeingEdited]}
          />}
        </Drawer>

        {referents.map((referent, i) => <ReferentItem
          key={i}
          index={i}
          onEditClick={() => setReferentIndexBeingEdited(i)}
          handleReferentsUpdate={handleReferentsUpdate}
          referent={referent}
        />)}

        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <AddButton onClick={() => setReferentIndexBeingEdited(referents.length + 1)} />
        </div>
      </div>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton onClick={handleSubmit} />
      </ActionsArea>
    </>
  );
}
