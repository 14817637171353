import { Button, Result as AntdResult } from "antd";
import { Link as RouterLink } from "react-router-dom";
import type { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { default as View, Content } from "../components/View";
import ContentCard from "../components/ContentCard";

const Result = styled(AntdResult)`
  padding: 10% 0;
`;

const Link = styled(RouterLink)`
  color: white;

  :hover {
    color: white;
  }
`;

const notFoundPathname = "/page-inconnue";

function HomeButton() {
  return (
    <Button shape="round" type="primary">
      <Link to="/">Retour à l'accueil</Link>
    </Button>
  );
}

function NotFoundCard() {
  return (
    <ContentCard>
      <Result
        status={404}
        title="Page inconnue"
        subTitle="Nous n'avons malheureusement pas trouvé ce que vous recherchiez"
        extra={<HomeButton />}
      />
    </ContentCard>
  );
}

export default function NotFoundView({ history }: RouteComponentProps) {
  if (history.location.pathname !== notFoundPathname)
    history.replace(notFoundPathname);

  window.onbeforeunload = () => {};

  return (
    <View>
      <Content>
        <NotFoundCard />
      </Content>
    </View>
  );
}
