import { Form } from 'antd';
import Input from '../Input';

const maxLength = 1000; // TODO
const pattern = new RegExp('');
const placeholder = 'Hôpital Aux bon soins';
const defaultErrorMessage = 'Veuillez saisir le nom d\'un centre hospitalier';
const defaultLabel = 'Centre hospitalier';
const defaultName = 'sHopital';

interface FormItemHopitalProps {
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function FormItemHopital({ disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = false }: FormItemHopitalProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <Input
        allowClear
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        size="large"
      />
    </Form.Item>
  );
}
