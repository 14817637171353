import { Form } from 'antd';
import Input from '../Input';

const maxLength = 14;
const pattern = new RegExp(`^(\\d{2}\\s){4}\\d{2}$`);
const placeholder = '06 05 04 03 02';
const defaultErrorMessage = 'Veuillez saisir un numéro de téléphone portable';
const defaultLabel = 'Téléphone portable';
const defaultName = 'sTelGsm';

interface FormItemGSMProps {
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

function normalize(numeroGSM?: string) {
  return numeroGSM?.replace(/(\d{0,2})\s?/g, '$1 ').trim();
}

export default function FormItemGSM({ disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = false }: FormItemGSMProps) {
  return (
    <Form.Item
      initialValue={normalize(initialValue)}
      label={label}
      name={name}
      normalize={normalize}
      rules={[
        { message: errorMessage, required },
        { message: 'Numéro de portable invalide', pattern }
      ]}
    >
      <Input
        allowClear
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        size="large"
        type="tel"
      />
    </Form.Item>
  );
}
