import { Form } from 'antd';
import Input from '../Input';

const maxLength = 1000; // TODO
const pattern = new RegExp('');
const placeholder = 'a1s2s3u3r4a5n5c6e6';
const defaultErrorMessage = 'Veuillez saisir le numéro de police de l\'assurance de responsabilité civile';
const defaultLabel = 'N° de police';
const defaultName = 'sPoliceAssurance';

interface FormItemAssurancePoliceProps {
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function FormItemAssurancePolice({ disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = true }: FormItemAssurancePoliceProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <Input
        allowClear
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        size="large"
      />
    </Form.Item>
  );
}
