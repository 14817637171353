import { Form } from 'antd';

import { ActionsArea, BackButton, NextButton } from '../../../components/buttons';
import { FormItemCommune } from '../../../components/formItems';
import type { Patient } from '../../../interfaces/ContratPret';
import ContentCard from '../../../components/ContentCard';
import Input from '../../../components/Input';

interface FormDateLieuSignatureProps {
  onPreviousClick: () => void;
  onSubmit: (values: { dSignatureDate: Date; sSignatureLieu: string; }) => void;
  patient: Patient;
  sSignatureLieu?: string;
}

interface FormSignatureValues {
  sSignatureDate: string,
  sSignatureLieu: string
}

export default function FormDateLieuSignature({ onPreviousClick, onSubmit, patient, sSignatureLieu }: FormDateLieuSignatureProps) {
  const handleSubmit = ({ sSignatureDate, sSignatureLieu }: FormSignatureValues) => onSubmit({
    dSignatureDate: new Date(sSignatureDate), sSignatureLieu
  });

  return (
    <>
      <ContentCard>
        <Form name="formSignature" onFinish={handleSubmit}>
          <FormItemCommune
            autoFocus
            errorMessage="Veuillez saisir le lieu de signature"
            initialValue={sSignatureLieu || patient.etatCivil?.sVille}
            label="Fait à"
            name="sSignatureLieu"
            required
          />
          <FormItemSignatureDate />
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          form="formSignature" /* form cancel centering style */ htmlType="submit" />
      </ActionsArea>
    </>
  );
}

function FormItemSignatureDate() {
  return (
    <Form.Item
      initialValue={new Date().toISOString().slice(0, 10)}
      label="Le"
      name="sSignatureDate"
      required
    >
      <Input disabled size="large" type="date" />
    </Form.Item>
  );
}
