import { Form } from 'antd';

import { default as Select, Option } from '../Select';

const placeholder = 'Sélectionner...';
const defaultErrorMessage = 'Veuillez sélectionner un type de signataire';
const defaultLabel = 'Type de signataire';
const defaultName = 'sTypeSignataire';

interface FormItemTypeSignataireProps {
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function FormItemTypeSignataire({ autoFocus, disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = false }: FormItemTypeSignataireProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[{ message: errorMessage, required }]}
    >
      <Select
        autoFocus={autoFocus}
        disabled={disabled}
        placeholder={placeholder}
        size="large"
      >
        <Option value="Patient">Patient</Option>
        <Option value="Représentant légal">Représentant légal</Option>
      </Select>
    </Form.Item>
  );
}
