import { useHistory } from 'react-router-dom';

let redirect = true;

export default function RedirectComponent() {
  const history = useHistory();
  if (redirect) {
    redirect = false;
    history.push("/");
  }
  return <></>;
}
