import {
  createContext,
  useContext,
  useEffect,
  useState,
  ComponentProps,
} from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";

import { getConfig } from "../api/config";
import type Config from "../interfaces/Config";
import { deconnexionAction } from "../redux/utilisateur/actions/deconnexion.action";

const ConfigContext = createContext<Config | undefined>(undefined);

type ConfigProviderProps = Partial<
  ComponentProps<typeof ConfigContext.Provider>
>;

export function ConfigProvider({ children, value }: ConfigProviderProps) {
  const dispatch = useDispatch();
  const [config, setConfig] = useState(value);

  useEffect(() => {
    if (!value) {
      getConfig()
        .then((data) => {
          setConfig(data);
        })
        .catch((error) => {
          message.error(
            `Echec de la récupération de la configuration (${error.message})`,
            5
          );
          dispatch<any>(deconnexionAction());
        });
    }
  }, [value]);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
}

export const useConfig = () => useContext(ConfigContext);
