import { Form, Radio, Space, Select } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { ActionsArea, BackButton, NextButton } from '../../../../components/buttons';
import type { Accords, Consentement } from '../../../../interfaces/ContratPret';
import ContentCard from '../../../../components/ContentCard';
import Switch from '../../../../components/Switch';
import TextArea from '../../../../components/TextArea';

import { useConfig } from '../../../../components/ConfigContext';

const Title = styled.h3`
  text-align: center;
`;

const Ul = styled.ul`
  font-size: smaller;
  list-style: '– ';
  padding: 0 11px;

  li {
    :not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

type CommonProps = {
  consentement?: Consentement;
  onPreviousClick: () => void;
  onSubmit: ({ consentement }: { consentement: Consentement }) => void;
};

type FormGenericAccordProps = CommonProps & {
  accordProperty: keyof Omit<Accords, 'sDonneesSanteRefus'>;
  required?: boolean;
  label: string;
};

type FormAccordPresenceAccompagnantsProps = CommonProps;
type FormAccordPriseEnChargeProps = CommonProps;
type FormAccordTelesuiviProps = CommonProps;
type FormAccordTraitementDonneesSanteProps = CommonProps;

function FormGenericAccord({ accordProperty, consentement, label, onPreviousClick, onSubmit, required = true }: FormGenericAccordProps) {
  const [toggleValue, setToggle] = useState(!!consentement?.accords?.[accordProperty]);

  const handleSubmit = () =>
    onSubmit({
      consentement: {
        ...consentement,
        accords: {
          ...consentement?.accords,
          [accordProperty]: toggleValue,
        },
      },
    });

  const toggle = () => setToggle((v) => !v);

  return (
    <>
      <ContentCard>
        <Title>Le signataire accepte&nbsp;:</Title>

        <Form style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '20px' }}>
          {label}

          <Form.Item style={{ margin: '0 0 0 5px' }}>
            <Switch autoFocus checked={toggleValue} checkedChildren="Oui" onChange={toggle} unCheckedChildren="Non" />
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton disabled={required && !toggleValue} onClick={handleSubmit} />
      </ActionsArea>
    </>
  );
}

export function FormAccordPresenceAccompagnants(props: FormAccordPresenceAccompagnantsProps) {
  const newProps = {
    ...props,
    consentement: {
      ...props.consentement,
      accords: {
        ...props.consentement?.accords,
        bPresenceAccompagnants: props.consentement?.accords?.bPresenceAccompagnants === false ? false : true,
      },
    },
  };
  return (
    <FormGenericAccord
      {...newProps}
      required={false}
      accordProperty="bPresenceAccompagnants"
      label="La présence occasionnelle au domicile du patient de professionnels de Santelys accompagnant l'intervenant"
    />
  );
}

export function FormAccordPriseEnCharge(props: FormAccordPriseEnChargeProps) {
  return <FormGenericAccord {...props} accordProperty="bPriseEnCharge" label="La prise en charge proposée par Santelys" />;
}

export function FormAccordTelesuivi({ consentement, onPreviousClick, onSubmit }: FormAccordTelesuiviProps) {
  const [toggleValue, setToggle] = useState(consentement?.accords?.bTelesuivi);
  const [sTelesuiviRefus, setsTelesuiviRefus] = useState(consentement?.accords?.sTelesuiviRefus);
  const config = useConfig();

  const handleSubmit = () =>
    onSubmit({
      consentement: {
        ...consentement,
        accords: {
          ...consentement?.accords,
          bTelesuivi: toggleValue,
          sTelesuiviRefus
        },
      },
    });

  return (
    <>
      <ContentCard>
        <Title>Le signataire accepte :</Title>

        <Form style={{ marginTop: '15px' }}>
          La mise en œuvre du dispositif de télésuivi proposé dans le cadre de la prise en charge
          <Form.Item style={{ margin: '0 0 0 5px', marginTop: 15, width: '100%' }}>
            <Radio.Group style={{ width: '100%' }} onChange={(event) => setToggle(event.target.value)} value={toggleValue}>
              <Space direction="vertical" style={{ gap: 15, width: '100%' }}>
                <Radio value={true}>Oui</Radio>
                <Radio value={false}>Non</Radio>
                {toggleValue === false ? (
                  <Select onChange={value => setsTelesuiviRefus(value)} placeholder="Motif du refus" size="large" value={sTelesuiviRefus} style={{ width: '100%' }}>
                    {config?.tMotifsRefusTeleops.map((motifRefus) => (
                      <Select.Option key={motifRefus.sCode} text={motifRefus.sLibelle} value={motifRefus.sLibelle}>
                        {motifRefus.sLibelle}
                      </Select.Option>
                    ))}
                  </Select>
                ) : null}
                <Radio value={null}>Non concerné</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton disabled={toggleValue === undefined} onClick={handleSubmit} />
      </ActionsArea>
    </>
  );
}

interface FormAccordTraitementDonneesSanteValues {
  bTraitementDonneesSante: boolean;
  sTraitementDonneesSanteRefus?: string;
}

export function FormAccordTraitementDonneesSante({ consentement, onPreviousClick, onSubmit }: FormAccordTraitementDonneesSanteProps) {
  const [toggleValue, setToggle] = useState(!!consentement?.accords?.bTraitementDonneesSante);

  const handleSubmit = ({ bTraitementDonneesSante, sTraitementDonneesSanteRefus }: FormAccordTraitementDonneesSanteValues) =>
    onSubmit({
      consentement: {
        ...consentement,
        accords: {
          ...consentement?.accords,
          bTraitementDonneesSante,
          sTraitementDonneesSanteRefus: bTraitementDonneesSante ? undefined : sTraitementDonneesSanteRefus,
        },
      },
    });

  const toggle = () => setToggle((v) => !v);

  return (
    <>
      <ContentCard>
        <Title>Le signataire accepte concernant ses données de santé&nbsp;:</Title>

        <Ul>
          <li>Leur enregistrement et analyse et leur transmission sécurisée aux professionnels participant à la prise en charge</li>

          <li>Le traitement anonyme à des fins statistiques et de recherche</li>

          <li>Leur conservation par un organisme agréé</li>
        </Ul>

        <Form name="formAccordTraitementDonneesSante" onFinish={handleSubmit} scrollToFirstError={{ behavior: 'smooth' }}>
          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '10px 0' }}>
            Après explication et sans réserve les points sus-cités
            <Form.Item initialValue={toggleValue} name="bTraitementDonneesSante" style={{ margin: '0 0 0 5px' }} valuePropName="checked">
              <Switch autoFocus checked={toggleValue} checkedChildren="Oui" onChange={toggle} unCheckedChildren="Non" />
            </Form.Item>
          </div>

          {toggleValue || (
            <Form.Item
              initialValue={consentement?.accords?.sTraitementDonneesSanteRefus}
              label="Raisons du refus"
              name="sTraitementDonneesSanteRefus"
              rules={[{ message: 'Veuillez renseigner la raison du refus', required: true }]}
            >
              <TextArea allowClear autoSize={{ minRows: 4, maxRows: 4 }} size="large" />
            </Form.Item>
          )}
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton form="formAccordTraitementDonneesSante" htmlType="submit" />
      </ActionsArea>
    </>
  );
}
