import axios from 'axios';

import type { Patient } from '../interfaces/ContratPret';
import config from '../appConfig';

export async function getPatient(iPKAnnuaire: number | string): Promise<Patient> {
  const { data } = await axios.get(
    `${config.serverURL}/patient/${iPKAnnuaire}`,
    { withCredentials: true }
  );
  return data;
}
