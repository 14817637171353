import { Button, Form, Radio, Space } from "antd";
import { useLayoutEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import type { ElementRef } from "react";
import styled from "styled-components";

import {
  ActionsArea,
  BackButton,
  NextButton,
} from "../../../components/buttons";
import type {
  Capacites,
  Formation,
  PersonneFormee,
  Activite,
} from "../../../interfaces/ContratPret";
import {
  FormItemNomDiffuseur,
  FormItemNomVoieAbord,
  FormItemNomsAppareils,
} from "../../../components/formItems";
import Checkbox from "../../../components/Checkbox";
import ContentCard from "../../../components/ContentCard";
import Input from "../../../components/Input";
import SignatureInput from "../../../components/SignatureInput";
import Switch from "../../../components/Switch";
import TextArea from "../../../components/TextArea";

const Title = styled.h3`
  text-align: center;
`;

type CommonProps = {
  formation?: Formation;
  onPreviousClick: () => void;
  onSubmit: ({ formation }: { formation: Formation }) => void;
};

type FormGenericFormationProps = CommonProps & {
  capacitesProperty: keyof Capacites;
  text: string;
  title?: string;
};

function FormGenericFormation({
  capacitesProperty,
  formation,
  onPreviousClick,
  onSubmit,
  text,
  title = "Les personnes formées sont capables",
}: FormGenericFormationProps) {
  const [toggleValue, setToggle] = useState(
    !!formation?.capacites?.[capacitesProperty]
  );

  const handleSubmit = () =>
    onSubmit({
      formation: {
        ...formation,
        capacites: {
          ...formation?.capacites,
          [capacitesProperty]: true,
        },
      },
    });

  const toggle = () => setToggle((v) => !v);

  return (
    <>
      <ContentCard>
        {title ? <Title>{title}&nbsp;:</Title> : ""}

        <Form
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            marginTop: title ? "20px" : "0",
          }}
        >
          <Form.Item style={{ margin: "0" }}>
            <Checkbox autoFocus checked={toggleValue} onChange={toggle}>
              {text}
            </Checkbox>
          </Form.Item>

          {/* <div style={{ marginLeft: "15px" }}>{text}</div> */}
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton disabled={!toggleValue} onClick={handleSubmit} />
      </ActionsArea>
    </>
  );
}

interface FormFormationAppareilProps {
  activites?: Activite[];
  formation?: Formation;
  onPreviousClick: () => void;
  onSubmit: ({ formation }: { formation: Formation }) => void;
}

export function FormFormationAppareil({
  activites,
  formation,
  onPreviousClick,
  onSubmit,
}: FormFormationAppareilProps) {
  const [bAppareilsNonConcerne, setbAppareilsNonConcerne] = useState(
    !!formation?.bAppareilsNonConcerne
  );

  const handleSubmit = ({ sAppareils }: { sAppareils: string }) =>
    onSubmit({
      formation: { ...formation, sAppareils, bAppareilsNonConcerne },
    });

  return (
    <>
      <ContentCard>
        <Form name="formFormationAppareil" onFinish={handleSubmit}>
          {/* si PERF Appareil non obligatoire */}
          <FormItemNomsAppareils
            disabled={bAppareilsNonConcerne}
            required={
              !activites?.some((a) => a === "P") && !bAppareilsNonConcerne
            }
            autoFocus
            initialValue={formation?.sAppareils}
          />
          <p style={{ fontSize: "smaller" }}>* Sauf batteries</p>

          <Form.Item
            style={{ margin: "0 0 0 5px", marginTop: 15, width: "100%" }}
          >
            <Checkbox
              onChange={(event) =>
                setbAppareilsNonConcerne(event.target.checked)
              }
              checked={bAppareilsNonConcerne}
            >
              Non concerné
            </Checkbox>
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          form="formFormationAppareil"
          /* form cancels centering style */ htmlType="submit"
        />
      </ActionsArea>
    </>
  );
}

type FormFormationMettreSousTensionProps = CommonProps;

export function FormFormationMettreSousTension(
  props: FormFormationMettreSousTensionProps
) {
  return (
    <FormGenericFormation
      {...props}
      capacitesProperty="bMettreSousTension"
      text="Mettre en marche le dispositif médical et l'arrêter"
    />
  );
}

type FormFormationAdapterConsommableProps = CommonProps;

export function FormFormationAdapterConsommable(
  props: FormFormationAdapterConsommableProps
) {
  return (
    <FormGenericFormation
      {...props}
      capacitesProperty="bAdapterConsommable"
      text="Adapter le consommable à l'appareil"
    />
  );
}

type FormFormationProgrammerPompeProps = CommonProps;

export function FormFormationProgrammerPompe(
  props: FormFormationProgrammerPompeProps
) {
  return (
    <FormGenericFormation
      {...props}
      capacitesProperty="bProgrammerPompe"
      text="Programmer la pompe selon la prescription et la notice fournisseur (débit, volume, purge, bolus, flux, verouillage, ...)"
    />
  );
}

type FormFormationPresenceGraviteDiffuseurProps = CommonProps;

export function FormFormationPresenceGraviteDiffuseur({
  formation,
  onSubmit,
  onPreviousClick,
}: FormFormationPresenceGraviteDiffuseurProps) {
  const [toggleValue, setToggle] = useState(
    !!formation?.bPresenceGraviteDiffuseur
  );

  const handleSubmit = () =>
    onSubmit({
      formation: {
        ...formation,
        bPresenceGraviteDiffuseur: toggleValue,
      },
    });

  return (
    <>
      <ContentCard>
        <Form style={{ marginTop: "15px" }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            Présence d'une perfusion gravité
            <Form.Item
              initialValue={toggleValue}
              name="bTraitementDonneesSante"
              style={{ margin: "0 0 0 5px" }}
              valuePropName="checked"
            >
              <Switch
                autoFocus
                checked={toggleValue}
                checkedChildren="Oui"
                onChange={(checked) => setToggle(checked)}
                unCheckedChildren="Non"
              />
            </Form.Item>
          </div>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          disabled={toggleValue === undefined}
          onClick={handleSubmit}
        />
      </ActionsArea>
    </>
  );
}

type FormFormationGererAlarmesProps = CommonProps;

export function FormFormationGererAlarmes({
  formation,
  onPreviousClick,
  onSubmit,
}: FormFormationGererAlarmesProps) {
  const [toggleValue, setToggle] = useState(
    formation?.capacites?.bGererAlarmes
  );

  const handleSubmit = () =>
    onSubmit({
      formation: {
        ...formation,
        capacites: {
          ...formation?.capacites,
          bGererAlarmes: toggleValue,
        },
      },
    });

  return (
    <>
      <ContentCard>
        <Title>Les personnes formées sont capables&nbsp;:</Title>

        <Form style={{ marginTop: "15px" }}>
          Gérer les alarmes
          <Form.Item
            style={{ margin: "0 0 0 5px", marginTop: 15, width: "100%" }}
          >
            <Radio.Group
              style={{ width: "100%" }}
              onChange={(event) => setToggle(event.target.value)}
              value={toggleValue}
            >
              <Space direction="vertical" style={{ gap: 15, width: "100%" }}>
                <Radio value={true}>Oui</Radio>
                <Radio value={false}>
                  Non concerné (appareil dépourvu d'alarme)
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          disabled={toggleValue === undefined}
          onClick={handleSubmit}
        />
      </ActionsArea>
    </>
  );
}

type FormFormationDesadapterConsommableProps = CommonProps;

export function FormFormationDesadapterConsommable(
  props: FormFormationDesadapterConsommableProps
) {
  return (
    <FormGenericFormation
      {...props}
      capacitesProperty="bDesadapterConsommable"
      text="Désadapter le consommable de l'appareil"
    />
  );
}

type FormFormationEntretenirProps = CommonProps;

export function FormFormationEntretenir({
  formation,
  onPreviousClick,
  onSubmit,
}: FormFormationEntretenirProps) {
  const [toggleValue, setToggle] = useState(formation?.capacites?.bEntretenir);

  const handleSubmit = () =>
    onSubmit({
      formation: {
        ...formation,
        capacites: {
          ...formation?.capacites,
          bEntretenir: toggleValue,
        },
      },
    });

  return (
    <>
      <ContentCard>
        <Title>Les personnes formées sont capables&nbsp;:</Title>

        <Form style={{ marginTop: "15px" }}>
          Entretien (nettoyage de l'appareil et des consommables)
          <Form.Item
            style={{ margin: "0 0 0 5px", marginTop: 15, width: "100%" }}
          >
            <Radio.Group
              style={{ width: "100%" }}
              onChange={(event) => setToggle(event.target.value)}
              value={toggleValue}
            >
              <Space direction="vertical" style={{ gap: 15, width: "100%" }}>
                <Radio value={true}>Oui</Radio>
                <Radio value={false}>Non concerné</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          disabled={toggleValue === undefined}
          onClick={handleSubmit}
        />
      </ActionsArea>
    </>
  );
}

type FormFormationUtiliserMaterielSecoursProps = CommonProps;

export function FormFormationUtiliserMaterielSecours({
  formation,
  onPreviousClick,
  onSubmit,
}: FormFormationUtiliserMaterielSecoursProps) {
  const [toggleValue, setToggle] = useState(
    formation?.capacites?.bUtiliserMaterielSecours
  );

  const handleSubmit = () =>
    onSubmit({
      formation: {
        ...formation,
        capacites: {
          ...formation?.capacites,
          bUtiliserMaterielSecours: toggleValue,
        },
      },
    });

  return (
    <>
      <ContentCard>
        <Title>Les personnes formées sont capables&nbsp;:</Title>

        <Form style={{ marginTop: "15px" }}>
          Utiliser le matériel de secours (si oxygène +20h/jour ; ventilation
          +16h/jour)
          <Form.Item
            style={{ margin: "0 0 0 5px", marginTop: 15, width: "100%" }}
          >
            <Radio.Group
              style={{ width: "100%" }}
              onChange={(event) => setToggle(event.target.value)}
              value={toggleValue}
            >
              <Space direction="vertical" style={{ gap: 15, width: "100%" }}>
                <Radio value={true}>Oui</Radio>
                <Radio value={false}>Non concerné</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          disabled={toggleValue === undefined}
          onClick={handleSubmit}
        />
      </ActionsArea>
    </>
  );
}

type FormFormationUtiliserConsommablesProps = CommonProps;

export function FormFormationUtiliserConsommables(
  props: FormFormationUtiliserConsommablesProps
) {
  return (
    <FormGenericFormation
      {...props}
      capacitesProperty="bUtiliserConsommables"
      text="Utiliser les consommables utiles à la prise en charge"
    />
  );
}

interface FormFormationDiffuseursProps {
  activites: Activite[];
  formation?: Formation;
  onPreviousClick: () => void;
  onSubmit: ({ formation }: { formation: Formation }) => void;
}

export function FormFormationDiffuseurs({
  activites,
  formation,
  onPreviousClick,
  onSubmit,
}: FormFormationDiffuseursProps) {
  const [bDiffuseurNonConcerne, setbDiffuseurNonConcerne] = useState(
    !!formation?.bDiffuseurNonConcerne
  );
  const handleSubmit = ({ sDiffuseurs }: { sDiffuseurs: string }) =>
    onSubmit({
      formation: { ...formation, sDiffuseurs, bDiffuseurNonConcerne },
    });

  /* si PERF et Appareil renseigné non obligatoire */

  const isRequired =
    (activites.some((a) => a === "P") &&
      formation?.sAppareils?.length &&
      formation?.sAppareils?.length > 0) ||
    bDiffuseurNonConcerne
      ? false
      : true;

  return (
    <>
      <ContentCard>
        <Form name="formFormationDiffuseurs" onFinish={handleSubmit}>
          <FormItemNomDiffuseur
            disabled={bDiffuseurNonConcerne}
            required={isRequired}
            autoFocus
            initialValue={formation?.sDiffuseurs}
          />
          <Checkbox
            checked={bDiffuseurNonConcerne}
            onChange={(event) => setbDiffuseurNonConcerne(event.target.checked)}
          >
            Non concerné
          </Checkbox>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          form="formFormationDiffuseurs"
          /* form cancels centering style */ htmlType="submit"
        />
      </ActionsArea>
    </>
  );
}

interface FormFormationVoieAbordProps {
  formation?: Formation;
  onPreviousClick: () => void;
  onSubmit: ({ formation }: { formation: Formation }) => void;
}

export function FormFormationVoieAbord({
  formation,
  onPreviousClick,
  onSubmit,
}: FormFormationVoieAbordProps) {
  const handleSubmit = ({ sVoieAbord }: { sVoieAbord: string }) =>
    onSubmit({
      formation: { ...formation, sVoieAbord },
    });

  return (
    <>
      <ContentCard>
        <Form name="formFormationVoieAbord" onFinish={handleSubmit}>
          <FormItemNomVoieAbord
            autoFocus
            initialValue={formation?.sVoieAbord}
          />
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          form="formFormationVoieAbord"
          /* form cancels centering style */ htmlType="submit"
        />
      </ActionsArea>
    </>
  );
}

type FormFormationRemplirDiffuseurProps = CommonProps;

export function FormFormationRemplirDiffuseur(
  props: FormFormationRemplirDiffuseurProps
) {
  return (
    <FormGenericFormation
      {...props}
      capacitesProperty="bRemplirDiffuseur"
      text="Remplir le diffuseur"
    />
  );
}

type FormFormationBrancherDiffuseurProps = CommonProps;

export function FormFormationBrancherDiffuseur(
  props: FormFormationBrancherDiffuseurProps
) {
  return (
    <FormGenericFormation
      {...props}
      capacitesProperty="bBrancherDiffuseur"
      text="Brancher/débrancher le diffuseur"
    />
  );
}

type FormFormationSurveillerDiffuseurProps = CommonProps;

export function FormFormationSurveillerDiffuseur(
  props: FormFormationSurveillerDiffuseurProps
) {
  return (
    <FormGenericFormation
      {...props}
      capacitesProperty="bSurveillerDiffuseur"
      text={
        props.formation?.bPresenceGraviteDiffuseur
          ? "Préparer la perfusion"
          : "Surveiller une prise en charge sous perfuseur (cf. mémo)"
      }
    />
  );
}

type FormFormationUtiliserPerfuseurProps = CommonProps;

export function FormFormationUtiliserPerfuseur(
  props: FormFormationUtiliserPerfuseurProps
) {
  return (
    <FormGenericFormation
      {...props}
      capacitesProperty="bUtiliserPerfuseur"
      text={
        props.formation?.bPresenceGraviteDiffuseur
          ? "Connecter et déconnecter le patient"
          : "Utiliser le perfuseur"
      }
    />
  );
}

type FormFormationUtiliserConsommablesPerfusionProps = CommonProps;

export function FormFormationUtiliserConsommablesPerfusion(
  props: FormFormationUtiliserConsommablesPerfusionProps
) {
  return (
    <FormGenericFormation
      {...props}
      capacitesProperty="bUtiliserConsommablesPerfusion"
      text="Utiliser les consommables utiles à la pose et à l'entretien de la voie d'abord (sets de soins, aiguilles, cathéter, pansements, ...)"
    />
  );
}

type FormFormationTrierDechetsProps = CommonProps;

export function FormFormationTrierDechets({
  formation,
  onSubmit,
  onPreviousClick,
}: FormFormationTrierDechetsProps) {
  const [toggleValue, setToggle] = useState(
    !!formation?.capacites?.bTrierDechets
  );

  const handleSubmit = () =>
    onSubmit({
      formation: {
        ...formation,
        capacites: {
          ...formation?.capacites,
          bTrierDechets: toggleValue,
        },
      },
    });

  return (
    <>
      <ContentCard>
        <Title>Les personnes formées acceptent&nbsp;:</Title>

        <Form style={{ marginTop: "15px" }}>
          La collecte des déchets est gérée par Santélys
          <Form.Item
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "0 0 0 5px",
              marginTop: 15,
              width: "100%",
            }}
          >
            <Switch
              checked={toggleValue}
              checkedChildren="Oui"
              onChange={(checked) => setToggle(checked)}
              unCheckedChildren="Non"
            />
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          disabled={toggleValue === undefined}
          onClick={handleSubmit}
        />
      </ActionsArea>
    </>
  );
}

interface FormFormationDiversProps {
  formation?: Formation;
  onPreviousClick: () => void;
  onSubmit: ({ formation }: { formation: Formation }) => void;
}

export function FormFormationDivers({
  formation,
  onPreviousClick,
  onSubmit,
}: FormFormationDiversProps) {
  const handleSubmit = ({ sDivers }: { sDivers: string }) =>
    onSubmit({
      formation: { ...formation, sDivers },
    });

  return (
    <>
      <ContentCard>
        <Form name="formFormationDivers" onFinish={handleSubmit}>
          <Form.Item
            initialValue={formation?.sDivers}
            label="Divers"
            name="sDivers"
          >
            <Input allowClear autoFocus placeholder="" size="large" />
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          form="formFormationDivers"
          /* form cancels centering style */ htmlType="submit"
        />
      </ActionsArea>
    </>
  );
}

type FormFormationBouteilleSecoursProps = CommonProps;

export function FormFormationBouteilleSecours({
  formation,
  onPreviousClick,
  onSubmit,
}: FormFormationBouteilleSecoursProps) {
  const [toggleValue, setToggle] = useState(
    formation?.capacites?.bBrancherBouteilleSecours
  );

  const handleSubmit = () =>
    onSubmit({
      formation: {
        ...formation,
        capacites: {
          ...formation?.capacites,
          bBrancherBouteilleSecours: toggleValue,
        },
      },
    });

  return (
    <>
      <ContentCard>
        <Title>Les personnes formées sont capables&nbsp;:</Title>

        <Form style={{ marginTop: "15px" }}>
          Effectuer la manœuvre de branchement sur la bouteille de secours et
          attestent l'avoir fait
          <Form.Item
            style={{ margin: "0 0 0 5px", marginTop: 15, width: "100%" }}
          >
            <Radio.Group
              style={{ width: "100%" }}
              onChange={(event) => setToggle(event.target.value)}
              value={toggleValue}
            >
              <Space direction="vertical" style={{ gap: 15, width: "100%" }}>
                <Radio value={true}>Oui</Radio>
                <Radio value={false}>Non concerné</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          disabled={toggleValue === undefined}
          onClick={handleSubmit}
        />
      </ActionsArea>
    </>
  );
}

interface FormFormationCommentairesProps {
  formation?: Formation;
  onPreviousClick: () => void;
  onSubmit: ({ formation }: { formation: Formation }) => void;
}

export function FormFormationCommentaires({
  formation,
  onPreviousClick,
  onSubmit,
}: FormFormationCommentairesProps) {
  const handleSubmit = ({ sCommentaires }: { sCommentaires: string }) =>
    onSubmit({
      formation: { ...formation, sCommentaires },
    });

  return (
    <>
      <ContentCard>
        <Form name="formFormationCommentaires" onFinish={handleSubmit}>
          <Form.Item
            initialValue={formation?.sCommentaires}
            label="Commentaires"
            name="sCommentaires"
          >
            <TextArea
              allowClear
              autoFocus
              autoSize={{ minRows: 4, maxRows: 4 }}
              size="large"
            />
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          form="formFormationCommentaires"
          /* form cancels centering style */ htmlType="submit"
        />
      </ActionsArea>
    </>
  );
}

interface FormFormationDechargeProps {
  formation?: Formation;
  onPreviousClick: () => void;
  onSubmit: ({ formation }: { formation: Formation }) => void;
}

export function FormFormationDecharge({
  formation,
  onPreviousClick,
  onSubmit,
}: FormFormationDechargeProps) {
  const [liberaux, setLiberaux] = useState(
    formation?.personnesFormees?.filter((pf) => pf.bLiberal) || []
  );
  const isNextButtonDisabled = liberaux.some((l) => !l.bDecharge);

  const handleChange = (checked: boolean, p: PersonneFormee) =>
    setLiberaux((liberaux) => {
      const index = liberaux.findIndex(
        (l) => l.sNom === p.sNom && l.sPrenom === p.sPrenom
      );
      liberaux.splice(index, 1, { ...liberaux[index], bDecharge: checked });
      return [...liberaux];
    });

  const handleSubmit = () =>
    onSubmit({
      formation: {
        ...formation,
        personnesFormees: formation?.personnesFormees?.map((pf) =>
          pf.sTypeTiers ? { ...pf, bDecharge: true } : pf
        ),
      },
    });

  return (
    <>
      <ContentCard>
        <Title>Décharge à destination des professionnels libéraux</Title>

        <Form>
          {liberaux.map((r, i) => (
            <div
              key={i}
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              {`${r.sNom?.toUpperCase()} ${r.sPrenom} (${r.sTypeTiers})`}

              <Form.Item style={{ margin: "0 0 0 5px" }}>
                <Switch
                  autoFocus={i === 0}
                  checked={r.bDecharge}
                  checkedChildren="Oui"
                  onChange={(checked) => handleChange(checked, r)}
                  unCheckedChildren="Non"
                />
              </Form.Item>
            </div>
          ))}
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton disabled={isNextButtonDisabled} onClick={handleSubmit} />
      </ActionsArea>
    </>
  );
}

interface FormFormationSignatureProps {
  onPreviousClick: () => void;
  onSubmit: (signature: string) => void;
  personneFormee?: PersonneFormee;
}

function FormFormationSignature({
  onPreviousClick,
  onSubmit,
  personneFormee,
}: FormFormationSignatureProps) {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(
    !personneFormee?.sSignature
  );
  const canvasEl = useRef<ElementRef<typeof SignatureInput>>(null);

  const clearSignatureCanvas = () => {
    canvasEl.current?.clear();
    setIsSubmitButtonDisabled(true);
  };

  const handleClick = () => {
    const dataUrl = canvasEl.current?.getTrimmedCanvas().toDataURL();
    clearSignatureCanvas();
    dataUrl && onSubmit(dataUrl);
  };

  useLayoutEffect(() => {
    if (personneFormee?.sSignature) {
      canvasEl.current?.fromDataURL(personneFormee?.sSignature);
      setIsSubmitButtonDisabled(false);
    }
  }, [personneFormee?.sSignature]);

  return (
    <>
      <ContentCard
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "50%",
        }}
      >
        {/* <Carousel dots={false} ref={carouselEl} style={{ height: '40px', width: '300px', backgroundColor: 'red' }}>
          {liberaux.map((liberal, i) => <h2 key={i}>{liberal}</h2>)}
        </Carousel> */}

        <h3>{`${personneFormee?.sNom?.toUpperCase()} ${
          personneFormee?.sPrenom
        }`}</h3>

        <SignatureInput
          backgroundColor="#edf3f4"
          onBegin={() => setIsSubmitButtonDisabled(false)}
          ref={canvasEl}
        />

        <Button
          danger
          disabled={isSubmitButtonDisabled}
          icon={<DeleteOutlined />}
          onClick={clearSignatureCanvas}
          shape="round"
          style={{ marginTop: "10px", width: "min-content" }}
          type="primary"
        >
          Effacer
        </Button>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton disabled={isSubmitButtonDisabled} onClick={handleClick} />
      </ActionsArea>
    </>
  );
}

interface FormFormationSignaturesProps {
  formation?: Formation;
  onPreviousClick: () => void;
  onSubmit: ({ formation }: { formation?: Formation }) => void;
}

export function FormFormationSignatures({
  formation,
  onPreviousClick,
  onSubmit,
}: FormFormationSignaturesProps) {
  const [idx, setIdx] = useState(0);

  const handlePreviousClick = () => {
    if (idx === 0) onPreviousClick();
    setIdx((i) => i - 1);
  };

  const handleSubmit = (signature: string) => {
    if (!formation?.personnesFormees) {
      onSubmit({ formation });
      return;
    }

    formation.personnesFormees[idx].sSignature = signature;
    if (idx + 1 < formation.personnesFormees.length) {
      setIdx((i) => i + 1);
    } else {
      onSubmit({ formation });
    }
  };

  return (
    <FormFormationSignature
      onPreviousClick={handlePreviousClick}
      onSubmit={handleSubmit}
      personneFormee={formation?.personnesFormees?.[idx]}
    />
  );
}
