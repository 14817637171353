import { Form } from 'antd';

import { default as Select, Option } from '../Select';
import { useConfig } from '../ConfigContext';

const pattern = new RegExp('');
const placeholder = 'Sélectionner...';
const defaultErrorMessage = 'Veuillez sélectionner un type de tiers';
const defaultLabel = 'Type de tiers';
const defaultName = 'sTypeTiers';

interface FormItemCodeTypeTiersProps {
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
  onSelect?: any;
}

export default function FormItemCodeTypeTiers({ autoFocus, disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = true, onSelect }: FormItemCodeTypeTiersProps) {
  const config = useConfig();

  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <Select autoFocus={autoFocus}
        disabled={disabled}
        placeholder={placeholder}
        onSelect={onSelect}
        size="large"
      >
        {config?.tTypesTiers.map(typeTiers =>
          <Option key={typeTiers.sCode} value={typeTiers.sLibelle}>
            {typeTiers.sLibelle}
          </Option>)
        }
      </Select>
    </Form.Item>
  );
}
