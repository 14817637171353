import { Result } from 'antd';

import ContentCard from '../components/ContentCard';
import View from '../components/View';

function SubTitle() {
  return (
    <>
      Il est impossible d'utiliser l'application en l'état.
      <br />
      Veuillez contacter votre administrateur.
    </>
  );
}

export default function FormValid() {
  return <View>
    <ContentCard>
      <Result
        status="error"
        title="L'application mal paramétrée"
        subTitle={<SubTitle />}
      />
    </ContentCard>
  </View>;
}
