import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useEffect } from "react";

import { default as View, Content } from "../../components/View";
import { connexionAction } from "../../redux/utilisateur/actions/connexion.action";
import { selectUtilisateur } from "../../redux/utilisateur/utilisateur.slice";
import IFrame from "./IFrame";
import config from "../../appConfig";

export default function LoginView() {
  const user = useSelector(selectUtilisateur);
  const dispatch = useDispatch();

  useEffect(() => {
    const getToken = ({ origin, data: { token } }: MessageEvent) => {
      if (origin === config.identityServerURL) {
        dispatch<any>(connexionAction(token));
      }
    };

    window.addEventListener("message", getToken);

    return () => window.removeEventListener("message", getToken);
  }, [dispatch]);

  if (user) return <Redirect to="/" />;

  return (
    <View>
      <Content>
        <IFrame
          src={`${config.identityServerURL}/login?src=${window.location.origin}${window.location.pathname}&theme=${process.env.REACT_APP_THEME}`}
        />
      </Content>
    </View>
  );
}
