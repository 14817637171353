import { Form } from 'antd';

import { ActionsArea, BackButton, NextButton } from '../../../../components/buttons';
import type { default as ContratPret, Activite } from '../../../../interfaces/ContratPret';
import { FormItemRemiseMateriel } from '../../../../components/formItems';
import ContentCard from '../../../../components/ContentCard';

interface FormRemiseMaterielProps {
  activites?: ContratPret['activites'];
  onPreviousClick: () => void;
  onSubmit: (values: { activites: ContratPret['activites'] }) => void;
}

export function FormRemiseMateriel({ activites, onPreviousClick, onSubmit }: FormRemiseMaterielProps) {
  const selectedActivities = [
    ...activites?.includes('I') ? ['I'] : [],
    ...activites?.includes('N') ? ['N'] : [],
    ...activites?.includes('O') ? ['O'] : [],
    ...activites?.includes('P') ? ['P'] : [],
    ...activites?.includes('R') ? ['R'] : []
  ] as Activite[];

  return (
    <>
      <ContentCard>
        <Form name="formRemiseMateriel" onFinish={onSubmit}>
          <FormItemRemiseMateriel selectedActivities={selectedActivities} />
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          form="formRemiseMateriel" // form cancel centering style
          htmlType="submit"
        />
      </ActionsArea>
    </>
  );
}
