import { Form, Divider } from 'antd';
import { useState } from 'react';

import { ActionsArea, BackButton, NextButton } from '../../../../../components/buttons';
import {
  FormItemAdresse,
  FormItemCodePostal,
  FormItemCodeTypeVoie,
  FormItemCommune,
  FormItemDateNaissance,
  InputFormItemNomOrganismeObligatoire,
  SelectFormItemNomOrganismeObligatoire,
  FormItemNomPrenomAssure,
  FormItemNumeroVoie,
  FormItemSecuriteSocialeCle,
  FormItemSecuriteSocialeNumero,
} from '../../../../../components/formItems';
import { useConfig } from '../../../../../components/ConfigContext';
import ContentCard from '../../../../../components/ContentCard';
import Switch from '../../../../../components/Switch';

import type { Patient } from '../../../../../interfaces/ContratPret';

interface FormAssureProps {
  onPreviousClick: () => void;
  onSubmit: ({ patient }: { patient: Patient }) => void;
  patient: Patient;
}

interface FormAssureValues {
  sDateNaissance?: string;
  sNomPrenomAssure?: string;
}

export function FormAssure({ onPreviousClick, onSubmit, patient }: FormAssureProps) {
  const [isPatientAssure, setIsPatientAssure] = useState(patient.couvertureSociale?.regimeObligatoire?.bPatientAssure ?? true);

  const handleSubmit = ({ sDateNaissance, sNomPrenomAssure }: FormAssureValues) =>
    onSubmit({
      patient: {
        ...patient,
        couvertureSociale: {
          ...patient.couvertureSociale,
          regimeObligatoire: {
            ...patient.couvertureSociale?.regimeObligatoire,
            bPatientAssure: isPatientAssure,
            dDateNaissance: sDateNaissance ? new Date(sDateNaissance) : undefined,
            sNomPrenomAssure: sNomPrenomAssure || undefined,
          },
        },
      },
    });

  return (
    <>
      <ContentCard>
        <Form name="formAssure" onFinish={handleSubmit}>
          <Form.Item
            initialValue={patient.couvertureSociale?.regimeObligatoire?.bPatientAssure}
            style={{ marginBottom: isPatientAssure ? 0 : undefined }}
            valuePropName="checked"
          >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              Le patient est l'assuré ?
              <Switch
                autoFocus
                checked={isPatientAssure}
                checkedChildren="Oui"
                defaultChecked={isPatientAssure}
                onChange={setIsPatientAssure}
                unCheckedChildren="Non"
              />
            </div>
          </Form.Item>

          {isPatientAssure || (
            <>
              <FormItemNomPrenomAssure initialValue={patient.couvertureSociale?.regimeObligatoire?.sNomPrenomAssure} />

              <FormItemDateNaissance
                initialValue={patient.couvertureSociale?.regimeObligatoire?.dDateNaissance}
                label="Date de naissance de l'assuré"
              />
            </>
          )}
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton form="formAssure" /* form cancels centering style */ htmlType="submit" />
      </ActionsArea>
    </>
  );
}

interface FormCaisseSecuriteSocialeProps {
  onPreviousClick: () => void;
  onSubmit: ({ patient }: { patient: Patient }) => void;
  patient: Patient;
}

interface FormCaisseSecuriteSocialeValues {
  sAdresse?: string;
  sCodePostal?: string;
  sCodeTypeVoie?: string;
  sNom: string;
  sNumeroVoie?: string;
  sVille: string;
}

export function FormCaisseSecuriteSociale({ onPreviousClick, onSubmit, patient }: FormCaisseSecuriteSocialeProps) {
  const [form] = Form.useForm();
  const config = useConfig();

  const handleChangeSelect = () => {
    form.setFieldsValue(
      config?.tOrganismeObligatoire.find((c) => `${c.sNom} (${c.sCodeAnnuaire})` === form.getFieldValue('sSelectNom')) || {
        sAdresse: undefined,
        sCodePostal: undefined,
        sCodeTypeVoie: undefined,
        sNom: undefined,
        sNumeroVoie: undefined,
        sTelFixe: undefined,
        sVille: undefined,
      }
    );
  };

  const handleSubmit = ({ sNom, ...adresse }: FormCaisseSecuriteSocialeValues) =>
    onSubmit({
      patient: {
        ...patient,
        couvertureSociale: {
          ...patient.couvertureSociale,
          regimeObligatoire: {
            ...patient.couvertureSociale?.regimeObligatoire,
            ...adresse,
            sNom,
          },
        },
      },
    });

  return (
    <>
      <ContentCard>
        <Form form={form} name="formCaisseSecuriteSociale" onFinish={handleSubmit}>
          <SelectFormItemNomOrganismeObligatoire
            autoFocus
            errorMessage="Veuillez saisir le nom de la caisse de sécurité sociale de la personne assurée"
            onChange={handleChangeSelect}
            initialValue={
              patient.couvertureSociale?.regimeObligatoire?.sNom
                ? `${patient.couvertureSociale?.regimeObligatoire?.sNom} (${patient.couvertureSociale?.regimeObligatoire?.sCodeAnnuaire})`
                : undefined
            }
          />
          <div>
            <Divider style={{ borderColor: 'white', fontWeight: 'unset' }}>Saisie manuelle</Divider>
            <InputFormItemNomOrganismeObligatoire
              autoFocus
              errorMessage="Veuillez saisir le nom de la caisse de sécurité sociale de la personne assurée"
              initialValue={patient.couvertureSociale?.regimeObligatoire?.sNom}
            />
            <div style={{ alignItems: 'start', display: 'grid', gridGap: '20px', gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <FormItemNumeroVoie initialValue={patient.couvertureSociale?.regimeObligatoire?.sNumeroVoie} />

              <FormItemCodeTypeVoie initialValue={patient.couvertureSociale?.regimeObligatoire?.sCodeTypeVoie} />
            </div>

            <FormItemAdresse initialValue={patient.couvertureSociale?.regimeObligatoire?.sAdresse} />

            <FormItemCodePostal initialValue={patient.couvertureSociale?.regimeObligatoire?.sCodePostal} />

            <FormItemCommune
              errorMessage="Veuillez saisir la commune de la caisse de sécurité sociale de la personne assurée"
              initialValue={patient.couvertureSociale?.regimeObligatoire?.sVille}
              required
            />
          </div>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton form="formCaisseSecuriteSociale" /* form cancels centering style */ htmlType="submit" />
      </ActionsArea>
    </>
  );
}

interface FormNumeroSecuriteSocialeProps {
  onPreviousClick: () => void;
  onSubmit: ({ patient }: { patient: Patient }) => void;
  patient: Patient;
}

interface FormNumeroSecuriteSocialeValues {
  sCleSecu: string;
  sNumeroSecu: string;
}

export function FormNumeroSecuriteSociale({ onPreviousClick, onSubmit, patient }: FormNumeroSecuriteSocialeProps) {
  const handleSubmit = ({ sCleSecu, sNumeroSecu }: FormNumeroSecuriteSocialeValues) =>
    onSubmit({
      patient: {
        ...patient,
        couvertureSociale: {
          ...patient.couvertureSociale,
          regimeObligatoire: {
            ...patient.couvertureSociale?.regimeObligatoire,
            sCleSecu,
            sNumeroSecu: sNumeroSecu.replace(/ /g, ''),
          },
        },
      },
    });

  return (
    <>
      <ContentCard>
        <Form name="formNumeroSecuriteSociale" onFinish={handleSubmit}>
          <FormItemSecuriteSocialeNumero
            autoFocus
            initialValue={patient.couvertureSociale?.regimeObligatoire?.sNumeroSecu}
            errorMessage="Veuillez saisir le numéro de sécurité sociale du patient"
          />

          <FormItemSecuriteSocialeCle
            errorMessage="Veuillez saisir la clé du numéro de sécurité sociale du patient"
            initialValue={patient.couvertureSociale?.regimeObligatoire?.sCleSecu}
          />
        </Form>

        <div style={{ fontWeight: 'bold', textAlign: 'justify' }}>Fournir une copie de l'attestion de la carte vitale</div>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton form="formNumeroSecuriteSociale" /* form cancels centering style */ htmlType="submit" />
      </ActionsArea>
    </>
  );
}
