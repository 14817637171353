import { Divider, Form, Row as AntdRow } from 'antd';
import type { ValidatorRule } from 'rc-field-form/lib/interface';
import { WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import type { Activite } from '../../interfaces/ContratPret';
import Checkbox from '../Checkbox';

const Row = styled(AntdRow)`
   margin-bottom: 20px;

   :is(:first-child) {
      margin-top: 10px;
   }

   :is(:last-child) {
      margin-bottom: 10px;
   }
`;

const validator: ValidatorRule['validator'] = async (_, v: boolean[]) => {
  if (!v.some(v => v)) throw new Error();
};

interface FormItemRemiseMaterielProps {
  selectedActivities?: Activite[];
}

export default function FormItemRemiseMateriel({ selectedActivities }: FormItemRemiseMaterielProps) {
  return (
    <>
      <Form.Item
        initialValue={selectedActivities}
        label="... du matériel et ses informations"
        name="activites"
        rules={[{ message: 'Veuillez sélectionner au moins une activité', required: true, validator }]}
        style={{ margin: '0' }}
      >
        <Checkbox.Group disabled={!!selectedActivities?.length}>
          <Row>
            <Checkbox value="R">Assistance respiratoire</Checkbox>
          </Row>

          <Row>
            <Checkbox value="O">Oxygénothérapie</Checkbox>
          </Row>

          <Row>
            <Checkbox value="P">Perfusion</Checkbox>
          </Row>

          <Row>
            <Checkbox value="I">Insulinothérapie par pompe</Checkbox>
          </Row>

          <Row>
            <Checkbox value="N">Nutrition</Checkbox>
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Warning />
    </>
  );
}

function Warning() {
  return (
    <>
      <Divider style={{ margin: '10px 0' }}>
        <WarningOutlined style={{ color: 'red' }} />
      </Divider>

      <div style={{ textAlign: 'center' }}>
        Le choix effectué ne sera plus modifiable.
        <br />
        En cas d'erreur, quittez l’application pour recommencer.
      </div>
    </>
  );
}
