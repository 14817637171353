import { Form } from 'antd';
import Input from '../Input';

const maxLength = 5;
const pattern = new RegExp('');
const placeholder = '2';
const defaultErrorMessage = 'Veuillez saisir une porte';
const defaultLabel = 'Porte';
const defaultName = 'sPorte';

interface FormItemPorteProps {
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function FormItemPorte({ disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = false }: FormItemPorteProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <Input allowClear
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        size="large" />
    </Form.Item>
  );
}
