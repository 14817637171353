import { Redirect, Route, Switch, useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import { selectUtilisateur } from "../../redux/utilisateur/utilisateur.slice";

import { default as View, Content } from "../../components/View";
import { getContratRoutes, getNextRouteConfig } from "./contratRoutes";
import type AppPath from "../../interfaces/AppPath";
import type ContratPret from "../../interfaces/ContratPret";
import Footer from "./Footer";
import Header from "./Header";
import NavDrawer from "./NavDrawer";
import NotFoundView from "../NotFound";
import ProtectedRoute from "../../components/ProtectedRoute";

interface ContratViewProps {
  preFilledContrat: ContratPret;
}

export default function ContratView({ preFilledContrat }: ContratViewProps) {
  const user = useSelector(selectUtilisateur);

  const [contrat, setContrat] = useState(preFilledContrat);

  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false);
  const [validatedFormRoutes, setValidatedFormRoutes] = useState<AppPath[]>([]);

  const history = useHistory();
  const routesConfig = getContratRoutes(contrat);

  const currentRouteConfigIndex = routesConfig.findIndex(
    (f) => f.path === history.location.pathname
  );
  const currentRouteConfig = routesConfig[currentRouteConfigIndex];

  const handlePreviousClick = () =>
    history.push(routesConfig[currentRouteConfigIndex - 1]?.path || "/");

  const handleSubmit = (newContratValues: Partial<ContratPret>) => {
    if (!user) {
      message.error(
        "Votre session a expiré.\nVeuillez vous identifier à nouveau.",
        5
      );
      history.push("/connexion");
      return false;
    }

    const newContratState: ContratPret = {
      ...contrat,
      ...newContratValues,
      intervenant: user,
      status: "sending",
    };

    const nextRouteConfig = getNextRouteConfig(
      currentRouteConfig?.path,
      newContratState
    );

    setContrat(newContratState);
    if (nextRouteConfig?.path) history.push(nextRouteConfig?.path);
    else history.push("/contrat/envoi", { contrat: newContratState });
  };

  const toggleDrawer = () => setIsNavDrawerOpen((v) => !v);

  useEffect(() => {
    setIsNavDrawerOpen(false);
    currentRouteConfig?.path &&
      setValidatedFormRoutes((previousValidatedRoutes) =>
        previousValidatedRoutes.includes(currentRouteConfig.path)
          ? previousValidatedRoutes
          : [...previousValidatedRoutes, currentRouteConfig.path]
      );
  }, [currentRouteConfig?.path, history.location.pathname]);

  if (!user) {
    return <Redirect to={{ pathname: "/connexion" }} />;
  }

  return (
    <View>
      <Header
        openNavDrawer={toggleDrawer}
        section={currentRouteConfig?.section}
        subSection={currentRouteConfig?.subSection}
      />

      <NavDrawer
        activites={contrat.activites}
        currentUrl={history.location.pathname}
        onClose={toggleDrawer}
        validatedFormRoutes={validatedFormRoutes}
        visible={isNavDrawerOpen}
      />

      <Content>
        <Switch>
          {routesConfig.map((routeConfig) => {
            return (
              <ProtectedRoute
                exact
                key={`${routeConfig?.section} - ${routeConfig?.subSection}`}
                path={routeConfig.path}
                render={() => (
                  <routeConfig.Component
                    {...contrat}
                    onPreviousClick={handlePreviousClick}
                    onSubmit={handleSubmit}
                  />
                )}
              />
            );
          })}

          <Redirect exact from="/contrat" to="/contrat/patient" />
          <Redirect
            exact
            from="/contrat/patient"
            to="/contrat/patient/etat-civil"
          />
          <Redirect
            exact
            from="/contrat/patient/etat-civil"
            to="/contrat/patient/etat-civil/noms"
          />
          <Redirect
            exact
            from="/contrat/consentement"
            to="/contrat/consentement/signataire"
          />
          <Redirect
            exact
            from="/contrat/consentement/acceptations"
            to="/contrat/consentement/acceptations/prise-en-charge"
          />
          <Redirect
            exact
            from="/contrat/formation"
            to="/contrat/formation/personnes-formees"
          />
          <Route component={NotFoundView} />
        </Switch>
      </Content>

      <Footer />
    </View>
  );
}
