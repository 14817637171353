import { Layout } from 'antd';
import styled from 'styled-components';

export default styled(Layout)`
  align-items: center;
  background: radial-gradient(circle, rgba(37,187,235,1) 20%, rgba(91,152,209,1) 80%);
  /* background-image: linear-gradient(to right top, #fadb05, #ffa031, #ff685f, #0077bf, #008ecf, #00a4de, #25bbeb); */
  /* background: linear-gradient(70deg, rgba(250,219,5,1) 0%, rgba(4,68,147,1) 10%, rgba(37,187,235,1) 75%, rgba(237,243,244,1) 100%); */
  /* background: linear-gradient(45deg, #fadb05 0%,#044493 33%,#25bbeb 100%); */
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
  overflow: hidden;
  position: relative;
  width: 100vw;
`;

export const Content = styled(Layout.Content)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
  width: 100%;
`;
