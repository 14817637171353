import { Form } from 'antd';
import Checkbox from '../Checkbox';

const defaultErrorMessage = 'Veuillez préciser le status du professionnel de santé';
const defaultLabel = 'Professionnel libéral';
const defaultName = 'bLiberal';

interface FormItemProfessionnelLiberalProps {
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: boolean;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function FormItemProfessionnelLiberal({ autoFocus, disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = false }: FormItemProfessionnelLiberalProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      name={name}
      rules={[{ message: errorMessage, required }]}
      valuePropName="checked"
    >
      <Checkbox
        autoFocus={autoFocus}
        defaultChecked={initialValue}
        disabled={disabled}
      >
        {label}
      </Checkbox>
    </Form.Item>
  );
}
