import axios from "axios";

import type ContratPret from "../interfaces/ContratPret";
import config from "../appConfig";

export async function sendContrat(contrat: ContratPret) {
  await axios.post(`${config.serverURL}/contrat`, contrat, {
    withCredentials: true,
  });
}
