import { Form } from 'antd';
import { default as Select, Option } from '../Select';
import { useConfig } from '../ConfigContext';

const placeholder = 'Sélectionner...';
const defaultLabel = "Choix de l'organisme obligatoire";
const defaultName = 'sSelectNom';

interface FormItemNomOrganismeObligatoireProps {
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  onChange: any;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function SelectFormItemNomOrganismeObligatoire({
  disabled,
  initialValue,
  onChange,
  label = defaultLabel,
  name = defaultName,
}: FormItemNomOrganismeObligatoireProps) {
  const config = useConfig();

  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      normalize={(nom?: string) => nom?.replace(/^./g, (firstLetter) => firstLetter.toUpperCase())}
      // rules={[{ message: errorMessage, pattern, required }]}
    >
      <Select allowClear showSearch onChange={onChange} disabled={disabled} placeholder={placeholder} size="large">
        {config?.tOrganismeObligatoire.map((organismeObligatoire) => (
          <Option key={`${organismeObligatoire.sNom}-${organismeObligatoire.sCodeAnnuaire}`} value={`${organismeObligatoire.sNom} (${organismeObligatoire.sCodeAnnuaire})`}>
            {organismeObligatoire.sNom} ({organismeObligatoire.sCodeAnnuaire})
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}
