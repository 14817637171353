import { useDispatch, useSelector } from "react-redux";
import {
  LoadingOutlined,
  RedoOutlined,
  CloudDownloadOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import type { RouteComponentProps } from "react-router-dom";
import { Button, ButtonProps, Spin, Result as AntdResult } from "antd";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { default as View, Content } from "../components/View";
import { sendContrat } from "../api/contrat";
import ContentCard from "../components/ContentCard";

import type ContratPret from "../interfaces/ContratPret";

import { selectUtilisateur } from "../redux/utilisateur/utilisateur.slice";
import { deconnexionAction } from "../redux/utilisateur/actions/deconnexion.action";

import config from "../appConfig";

const SendingCard = styled(ContentCard)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: center;
`;

const SpinnerIndicator = styled(LoadingOutlined)`
  color: #f0d80f;
  font-size: 64px;
  margin: 30px;
`;

const Title = styled.h1`
  margin-bottom: 50px;
`;

const Result = styled(AntdResult)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  padding: 10% 0;
`;

const SendingFailureCard = styled(ContentCard)`
  height: 90%;
`;

const Telechargement = styled.div`
  margin: 0;
  padding: 60px 0 0;
  text-align: left;

  & ol {
    margin: 0;
    padding: 10px 0 0 20px;
  }
  & ol li {
    padding: 0 0 10px;
    font-weight: bold;
  }
`;

function RetryButton(props: ButtonProps) {
  return (
    <Button
      icon={<RedoOutlined />}
      shape="round"
      size="large"
      type="primary"
      {...props}
    >
      Réessayer
    </Button>
  );
}

const TelachergerDataLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TelachergerDataLink = styled.a`
  display: inline-bloc;
  background-color: rgb(240, 216, 15);
  border: none;
  box-shadow: rgb(4 68 147) 0px 5px 5px;
  color: rgb(4, 68, 147);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  text-decoration: none;
  height: 40px;
  line-height: 1.5715;
  padding: 6.4px 20px;
  margin: 20px auto 0;
  font-size: 16px;
  border-radius: 40px;
  cursor: pointer;
  -webkit-appearance: button;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;

  &:hover,
  &:active,
  &:visited {
    color: rgb(4, 68, 147);
  }
`;

const IFrame = styled.iframe`
  position: absolute;
  top: -100;
  left: -100;
  opacity: 0;
  width: 0;
  height: 0;
  border: 0;
`;

function getDataFilename(): string {
  const date = new Date();
  const dateString = date.toISOString().split("T")[0];
  const timeString = date.toString().split(" ")[4].replace(/:/gi, "-");

  return `RCP-${dateString}_${timeString}.data`;
}

type SendingViewProps = RouteComponentProps<{}, {}, { contrat: ContratPret }>;

export default function SendingView({ history, location }: SendingViewProps) {
  const dispatch = useDispatch();
  const user = useSelector(selectUtilisateur);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const contrat = location.state.contrat;

  const send = useCallback(() => {
    if (contrat) {
      sendContrat(contrat)
        .then(() => {
          document.cookie = `contrat=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;SameSite=strict;Secure;`;
          history.replace("/contrat/envoi/succes");
        })
        .catch((error) => {
          setError(error.message);
          setIsError(true);
        });
    }
  }, [history, contrat]);

  const handleTelechargementData = () => {
    window.onbeforeunload = () => {
      document.cookie = `contrat=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;SameSite=strict;Secure;`;

      dispatch<any>(deconnexionAction());
    };
  };

  const retry = () =>
    history.push("/contrat/envoi/", { contrat: location.state.contrat });

  useEffect(() => {
    send();
  }, [send]);

  if (isError) {
    return (
      <View>
        <Content>
          <SendingFailureCard>
            <Result
              status="error"
              title="Echec de l'envoi du contrat"
              subTitle={error}
              extra={
                <>
                  <RetryButton onClick={retry} disabled={!user} />

                  <Telechargement>
                    Si malgré plusieurs tentatives l'envoi échoue encore :
                    <ol>
                      <li>
                        Téléchargez le fichier des données du contrat en
                        cliquant sur le lien ci-dessous.
                      </li>
                      <li>Transmettez ce fichier à votre administrateur.</li>
                    </ol>
                    <TelachergerDataLinkWrapper>
                      {user ? (
                        <TelachergerDataLink
                          href={`data:text/plain;charset=utf-8,${encodeURIComponent(
                            JSON.stringify(location.state.contrat)
                          )}`}
                          onClick={handleTelechargementData}
                          download={getDataFilename()}
                        >
                          <CloudDownloadOutlined /> Télécharger le fichier
                        </TelachergerDataLink>
                      ) : (
                        <>
                          <IFrame
                            src={`${config.identityServerURL}/logout`}
                            title="logout"
                          ></IFrame>
                          <TelachergerDataLink href="/">
                            <LogoutOutlined /> Terminer la saisie
                          </TelachergerDataLink>
                        </>
                      )}
                    </TelachergerDataLinkWrapper>
                  </Telechargement>
                </>
              }
            />
          </SendingFailureCard>
        </Content>
      </View>
    );
  } else {
    return (
      <View>
        <Content>
          <SendingCard>
            <Title>Envoi en cours</Title>
            <Spin indicator={<SpinnerIndicator />} />
          </SendingCard>
        </Content>
      </View>
    );
  }
}
