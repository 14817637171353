import { useDispatch } from "react-redux";
import { Result as AntdResult } from "antd";
import styled from "styled-components";

import { default as View, Content } from "../components/View";
import ContentCard from "../components/ContentCard";

import config from "../appConfig";

import { deconnexionAction } from "../redux/utilisateur/actions/deconnexion.action";

const Result = styled(AntdResult)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  padding: 10% 0;
`;

const SendingSuccessCard = styled(ContentCard)`
  height: 90%;
`;

const IFrame = styled.iframe`
  position: absolute;
  top: -100;
  left: -100;
  opacity: 0;
  width: 0;
  height: 0;
  border: 0;
`;

export default function SendingSuccess() {
  const dispatch = useDispatch();

  document.cookie = `contrat=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;SameSite=strict;Secure;`;

  dispatch<any>(deconnexionAction());

  window.onbeforeunload = () => {};

  return (
    <View>
      <Content>
        <SendingSuccessCard>
          <Result
            status="success"
            title="Contrat signé"
            subTitle={
              <span>
                Votre session a été correctement clôturée. <br />
                Vous pouvez fermer cette application et terminer votre visite
                dans SMART.
              </span>
            }
          />
        </SendingSuccessCard>
        <IFrame
          src={`${config.identityServerURL}/logout`}
          title="logout"
        ></IFrame>
      </Content>
    </View>
  );
}
