import { Steps } from 'antd';
import styled from 'styled-components';

const color = '#217ab5';

export default styled(Steps)`
  --color: ${color};
  --color-50: ${color}50;

  .ant-steps-item-title {
    padding: 0;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: transparent;
    border-color: var(--color-50);
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: var(--color-50);
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    /* background: #fadb05;
    border-color: var(--color); */
    background: var(--color);
    border-color: var(--color);
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    /* color: var(--color); */
    color: white;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: var(--color);
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: transparent;
    border-color: var(--color);
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: var(--color);
  }
`;
