import axios from 'axios';

import type Config from '../interfaces/Config';
import config from '../appConfig';

export async function getConfig(): Promise<Config> {
  const { data } = await axios.get(
    `${config.serverURL}/config`,
    { withCredentials: true }
  );
  return data;
}
