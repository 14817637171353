import { Switch } from 'antd';
import styled from 'styled-components';

const color1 = '#f0d80f';
const color2 = '#217ab5';

export default styled(Switch)`
  background-color: ${props => props.checked ? color1 : color2};

  .ant-switch-inner {
    color: ${props => props.checked ? color2 : color1};
  }
`;
