import { Form } from 'antd';

import { default as Select, Option } from '../Select';
import { useConfig } from '../ConfigContext';

const pattern = new RegExp('');
const placeholder = 'Sélectionner...';
const defaultErrorMessage = 'Veuillez sélectionner un type de voie';
const defaultLabel = 'Type de voie';
const defaultName = 'sCodeTypeVoie';

interface FormItemCodeTypeVoieProps {
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function FormItemCodeTypeVoie({ disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = false }: FormItemCodeTypeVoieProps) {
  const config = useConfig();

  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <Select
        disabled={disabled}
        placeholder={placeholder}
        size="large"
      >
        {config?.tTypesVoie.map(typeVoie =>
          <Option key={typeVoie.sCode} value={typeVoie.sCode}>
            {typeVoie.sLibelle}
          </Option>)
        }
      </Select>
    </Form.Item>
  );
}
