import type { Action } from "@reduxjs/toolkit";
import type { ThunkAction } from "redux-thunk";
import { verify as verifyJwt } from "jsonwebtoken";
import axios from "axios";

import { connexionSucces, connexionErreur } from "../utilisateur.slice";
import type { Identite } from "../../../interfaces/Identite";
import config from "../../../appConfig";

function getCookie(name: string) {
  const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

export function authentificationAction(): ThunkAction<
  void,
  Identite,
  unknown,
  Action<string>
> {
  return async (dispatch) => {
    const token = getCookie("Authorization");

    if (token) {
      try {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        const response = await axios(
          `${config.identityServerURL}/api/auth/verify`,
          {
            method: "POST",
            data: {},
            withCredentials: true,
          }
        );

        const {
          data: { user },
        } = response;

        if (user) {
          document.cookie = `Authorization=${token};path=/;secure;SameSite=strict`;

          dispatch<any>(connexionSucces({ user, token }));
        }
        // const payload = verifyJwt(token, config.jwtPUBLIC!, {
        //   algorithms: ["RS512"],
        // });

        // document.cookie = `Authorization=${token};path=/;secure;SameSite=strict`;

        // axios.defaults.headers.common.Authorization = `Bearer ${token}`;

        // dispatch<any>(connexionSucces({ user: payload as Identite, token }));
      } catch {
        dispatch<any>(connexionErreur({ sErreur: "Token invalide" }));
      }
    }
  };
}
