import { Form, Radio, Space } from "antd";
import type { ReactNode } from "react";
import { useState } from "react";

import Input from "../../../../components/Input";
import {
  ActionsArea,
  BackButton,
  NextButton,
} from "../../../../components/buttons";
import type {
  Consentement,
  DocumentsRemis,
} from "../../../../interfaces/ContratPret";
import Checkbox from "../../../../components/Checkbox";
import ContentCard from "../../../../components/ContentCard";

type CommonProps = {
  consentement?: Consentement;
  onPreviousClick: () => void;
  onSubmit: ({ consentement }: { consentement: Consentement }) => void;
};

type FormGenericDocumentProps = CommonProps & {
  document: string;
  documentsRemisProperty: keyof DocumentsRemis;
  note?: ReactNode;
  onSubmit: ({ consentement }: { consentement: Consentement }) => void;
  required?: boolean;
};

type FormDocAssuranceAutomobileProps = CommonProps;
type FormDocAssuranceImmeubleProps = CommonProps;
type FormDocCarnetDeSuiviProps = CommonProps;
type FormDocConsignesSecuriteOxygeneProps = CommonProps;
type FormDocDeplacementsProps = CommonProps;
type FormChartePriseEnChargePrestataireServiceProps = CommonProps;
type FormDocExtranetPatientProps = CommonProps;
type FormDocFormationProps = CommonProps;
type FormDocGestionDechetsProps = CommonProps & {
  activites: any[];
};
type FormDocImportantARretenirProps = CommonProps;
type FormDocInstallationElectriqueProps = CommonProps;
type FormDocLettrePresentationProps = CommonProps;
type FormDocLivretNutritionProps = CommonProps;
type FormDocModaliteSchemaTherapeutiqueProps = CommonProps;
type FormDocPriseEnChargeFinanciereProps = CommonProps;
type FormDocTelesuiviProps = CommonProps;
type FormDocTraitementDonneesSantePersonnellesProps = CommonProps;

function FormGenericDocument({
  document,
  consentement,
  documentsRemisProperty,
  note,
  onPreviousClick,
  onSubmit,
  required = true,
}: FormGenericDocumentProps) {
  const [toggleValue, setToggle] = useState(
    !!consentement?.documentsRemis?.[documentsRemisProperty]
  );

  const handleSubmit = () =>
    onSubmit({
      consentement: {
        ...consentement,
        documentsRemis: {
          ...consentement?.documentsRemis,
          [documentsRemisProperty]: toggleValue,
        },
      },
    });

  const toggle = () => setToggle((v) => !v);

  return (
    <>
      <ContentCard>
        <h3 style={{ textAlign: "center" }}>
          Le signataire atteste avoir pris connaissance du document&nbsp;:
        </h3>

        <Form
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
          }}
        >
          <Form.Item style={{ margin: "0" }}>
            <Checkbox autoFocus checked={toggleValue} onChange={toggle}>
              {document}
            </Checkbox>
          </Form.Item>

          {/* <div style={{ marginLeft: '15px' }}>{document}</div> */}
        </Form>

        <p
          style={{
            fontSize: "smaller",
            margin: "15px 0 0",
            textAlign: "center",
          }}
        >
          {note}
        </p>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          disabled={required ? !toggleValue : false}
          onClick={handleSubmit}
        />
      </ActionsArea>
    </>
  );
}

export function FormChartePriseEnChargePrestataireService(
  props: FormChartePriseEnChargePrestataireServiceProps
) {
  return (
    <FormGenericDocument
      {...props}
      document="Charte de la personne prise en charge par un prestataire de service"
      documentsRemisProperty="bChartePriseEnChargePrestataireService"
      note="Ne sont pas destinataires les patients pris en charge dans le cadre d’une Hospitalisation à Domicile"
    />
  );
}

export function FormDocLettrePresentation(
  props: FormDocLettrePresentationProps
) {
  return (
    <FormGenericDocument
      {...props}
      document="Lettre de présentation"
      documentsRemisProperty="bLettrePresentation"
    />
  );
}

export function FormDocPriseEnChargeFinanciere(
  props: FormDocPriseEnChargeFinanciereProps
) {
  return (
    <FormGenericDocument
      {...props}
      document="Prise en charge financière"
      documentsRemisProperty="bPriseEnChargeFinanciere"
    />
  );
}

export function FormDocImportantARretenir(
  props: FormDocImportantARretenirProps
) {
  return (
    <FormGenericDocument
      {...props}
      document="Important à retenir"
      documentsRemisProperty="bImportantARretenir"
    />
  );
}

export function FormDocTraitementDonneesSantePersonnelles(
  props: FormDocTraitementDonneesSantePersonnellesProps
) {
  return (
    <FormGenericDocument
      {...props}
      document="Traitement des données de santé à caractère personnel"
      documentsRemisProperty="bTraitementDonneesSantePersonnelles"
    />
  );
}

export function FormDocCarnetDeSuivi(props: FormDocCarnetDeSuiviProps) {
  return (
    <FormGenericDocument
      {...props}
      document="Carnet de suivi"
      documentsRemisProperty="bCarnetDeSuivi"
    />
  );
}

export function FormDocInstallationElectrique({
  consentement,
  onPreviousClick,
  onSubmit,
}: FormDocInstallationElectriqueProps) {
  const [toggleValue, setToggle] = useState(
    consentement?.documentsRemis?.bInstallationElectrique
  );

  const handleSubmit = () =>
    onSubmit({
      consentement: {
        ...consentement,
        documentsRemis: {
          ...consentement?.documentsRemis,
          bInstallationElectrique: toggleValue,
        },
      },
    });

  return (
    <>
      <ContentCard>
        <h3 style={{ textAlign: "center" }}>
          Le signataire atteste avoir pris connaissance du document&nbsp;:
        </h3>

        <Form style={{ marginTop: 15 }}>
          <Form.Item
            style={{ margin: "0 0 0 5px", marginTop: 15, width: "100%" }}
          >
            Installation électrique et conduite à tenir en cas de coupure
            <Radio.Group
              style={{ width: "100%", marginTop: 15 }}
              onChange={(event) => setToggle(event.target.value)}
              value={toggleValue}
            >
              <Space direction="vertical" style={{ gap: 15, width: "100%" }}>
                <Radio value={true}>Oui</Radio>
                <Radio value={false}>Non concerné</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          disabled={toggleValue === undefined}
          onClick={handleSubmit}
        />
      </ActionsArea>
    </>
  );
  // return (
  //   <FormGenericDocument
  //     {...props}
  //     document="Installation électrique et conduite à tenir en cas de coupure"
  //     documentsRemisProperty="bInstallationElectrique"
  //   />
  // );
}

export function FormDocConsignesSecuriteOxygene(
  props: FormDocConsignesSecuriteOxygeneProps
) {
  return (
    <FormGenericDocument
      {...props}
      document="Consignes de sécurité oxygène et matériel de secours"
      documentsRemisProperty="bConsignesSecuriteOxygene"
    />
  );
}

export function FormDocAssuranceImmeuble(props: FormDocAssuranceImmeubleProps) {
  return (
    <FormGenericDocument
      {...props}
      document="Information assurance immeuble incendie"
      documentsRemisProperty="bAssuranceImmeuble"
    />
  );
}

export function FormDocAssuranceAutomobile(
  props: FormDocAssuranceAutomobileProps
) {
  return (
    <FormGenericDocument
      {...props}
      document="Information assurance automobile"
      documentsRemisProperty="bAssuranceAutomobile"
    />
  );
}

export function FormDocLivretNutrition(props: FormDocLivretNutritionProps) {
  return (
    <FormGenericDocument
      {...props}
      document="Livret de nutrition entérale"
      documentsRemisProperty="bLivretNutrition"
    />
  );
}

export function FormDocModaliteSchemaTherapeutique(
  props: FormDocModaliteSchemaTherapeutiqueProps
) {
  return (
    <FormGenericDocument
      {...props}
      document="Modalités spécifiques concernant le schéma thérapeutique"
      documentsRemisProperty="bModaliteSchemaTherapeutique"
    />
  );
}

export function FormDocDeplacements(props: FormDocDeplacementsProps) {
  return (
    <FormGenericDocument
      {...props}
      document="Déplacements en France ou à l'étranger"
      documentsRemisProperty="bDeplacements"
    />
  );
}

export function FormDocGestionDechets(props: FormDocGestionDechetsProps) {
  return (
    <FormGenericDocument
      {...props}
      required={props.activites.includes("P") ? true : false}
      document="Gestion des déchets d'activité de soins"
      documentsRemisProperty="bGestionDechets"
    />
  );
}

export function FormDocTelesuivi(props: FormDocTelesuiviProps) {
  const { consentement, onSubmit, onPreviousClick } = props;
  const [toggleValue, setToggle] = useState(
    !!consentement?.documentsRemis?.bInfoTelesuivi
  );
  const [
    sDocInfoDonneeDispositifTelesuivi,
    setSDocInfoDonneeDispositifTelesuivi,
  ] = useState(
    consentement?.documentsRemis?.sDocInfoDonneeDispositifTelesuivi || ""
  );

  const handleSubmit = () =>
    onSubmit({
      consentement: {
        ...consentement,
        documentsRemis: {
          ...consentement?.documentsRemis,
          bInfoTelesuivi: toggleValue,
          sDocInfoDonneeDispositifTelesuivi,
        },
      },
    });

  const toggle = () => setToggle((v) => !v);

  return (
    <>
      <ContentCard>
        <h3 style={{ textAlign: "center" }}>
          Le signataire atteste avoir pris connaissance du document&nbsp;:
        </h3>

        <Form
          style={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            marginTop: "20px",
          }}
        >
          <Form.Item style={{ margin: "0" }}>
            <Checkbox autoFocus checked={toggleValue} onChange={toggle} />
          </Form.Item>

          <div style={{ marginLeft: "15px", maxWidth: "85%" }}>
            Informations relatives à l'enregistrement des données de traitement
            et à la mise en œuvre d'un dispositif de télésuivi
          </div>

          <Input
            style={{ marginTop: "15px" }}
            allowClear
            placeholder={"Document"}
            size="large"
            name="sDocInfoDonneeDispositifTelesuivi"
            value={sDocInfoDonneeDispositifTelesuivi}
            onChange={(evt: any) => {
              setSDocInfoDonneeDispositifTelesuivi(evt.target.value);
            }}
          />
        </Form>
      </ContentCard>
      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          disabled={
            !toggleValue || sDocInfoDonneeDispositifTelesuivi.length === 0
          }
          onClick={handleSubmit}
        />
      </ActionsArea>
    </>
  );
}

export function FormDocFormation(props: FormDocFormationProps) {
  return (
    <FormGenericDocument
      {...props}
      document="Formation et évaluation des compétences"
      documentsRemisProperty="bFormation"
    />
  );
}

export function FormDocExtranetPatient(props: FormDocExtranetPatientProps) {
  return (
    <FormGenericDocument
      {...props}
      document="Information relative à l'Extranet Patient"
      documentsRemisProperty="bInfoExtranet"
    />
  );
}
