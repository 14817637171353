import { Form as AntdForm } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { ActionsArea, BackButton, NextButton } from '../../../../components/buttons';
import type { Consentement } from '../../../../interfaces/ContratPret';
import ContentCard from '../../../../components/ContentCard';
import Switch from '../../../../components/Switch';

const Form = styled(AntdForm)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;

const Title = styled.h3`
  text-align: center;
`;

interface FormInformationSecuriteProps {
  consentement?: Consentement;
  onPreviousClick: () => void;
  onSubmit: (values: any) => void;
}

export function FormInformationSecurite({ consentement, onPreviousClick, onSubmit }: FormInformationSecuriteProps) {
  const [toggleValue, setToggle] = useState(!!consentement?.bInformationSecurite);

  const handleSubmit = () => onSubmit({ consentement: { ...consentement, bInformationSecurite: true } });

  const toggle = () => setToggle(v => !v);

  return (
    <>
      <ContentCard>
        <Title>Le signataire atteste que&nbsp;:</Title>

        <Form>
          Les normes de sécurité en vigueur afin de garantir la sécurité du
          patient et celle de son entourage lui ont bien été expliquées

          <Form.Item style={{ margin: '0 0 0 5px' }}>
            <Switch
              autoFocus
              checked={toggleValue}
              checkedChildren="Oui"
              onChange={toggle}
              unCheckedChildren="Non"
            />
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton disabled={!toggleValue} onClick={handleSubmit} />
      </ActionsArea>
    </>
  );
}
