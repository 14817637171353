import { Form } from 'antd';
import TextArea from '../TextArea';

const maxLength = 1000; // TODO
const pattern = new RegExp('');
const placeholder = 'Appareil(s)';
const defaultErrorMessage = 'Veuillez saisir le(s) nom(s) de(s) appareil(s)';
const defaultLabel = 'Nom(s) de(s) appareil(s)';
const defaultName = 'sAppareils';

interface FormItemNomsAppareilsProps {
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function FormItemNomsAppareils({ autoFocus, disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = true }: FormItemNomsAppareilsProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <TextArea
        allowClear
        autoFocus={autoFocus}
        autoSize={{ minRows: 5, maxRows: 8 }}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        size="large"
      />
    </Form.Item>
  );
}
