import { Redirect, Route } from "react-router-dom";
import type { RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { ConfigProvider } from "./ConfigContext";

import { selectUtilisateur } from "../redux/utilisateur/utilisateur.slice";

export default function ProtectedRoute(props: RouteProps) {
  const user = useSelector(selectUtilisateur);

  if (user && !user.bInactif) {
    return (
      <ConfigProvider>
        <Route {...props} />
      </ConfigProvider>
    );
  }

  return (
    <Route
      {...props}
      component={() => <Redirect to={{ pathname: "/connexion" }} />}
    />
  );
}
