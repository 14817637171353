import { useRef, useState } from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import type { ElementRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ActionsArea, BackButton, NextButton } from '../../../components/buttons';
import { selectUtilisateur } from '../../../redux/utilisateur/utilisateur.slice';
import ContentCard from '../../../components/ContentCard';
import SignatureInput from '../../../components/SignatureInput';

const Clearbutton = styled(Button)`
  margin-top: 10px;
  width: min-content;
`;

const SignatureContentCard = styled(ContentCard)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 50%;
`;

interface FormSignatureProps {
  onPreviousClick: () => void;
  onSubmit: (values: { signature: string }) => void;
}

export default function FormSignature({ onPreviousClick, onSubmit }: FormSignatureProps) {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const canvasEl = useRef<ElementRef<typeof SignatureInput>>(null);
  const user = useSelector(selectUtilisateur);

  const clearSignatureCanvas = () => {
    canvasEl.current?.clear();
    setIsSubmitButtonDisabled(true);
  };

  const handleClick = () => {
    const dataUrl = canvasEl.current?.getTrimmedCanvas().toDataURL();
    return dataUrl && onSubmit({ signature: dataUrl });
  };

  return (
    <>
      <SignatureContentCard>
        <h3>{`${user?.sNom.toLocaleUpperCase()} ${user?.sPrenom}`}</h3>

        <SignatureInput
          backgroundColor="#edf3f4"
          onBegin={() => setIsSubmitButtonDisabled(false)}
          ref={canvasEl}
        />

        <Clearbutton
          danger
          disabled={isSubmitButtonDisabled}
          icon={<DeleteOutlined />}
          onClick={clearSignatureCanvas}
          shape="round"
          type="primary"
        >
          Effacer
        </Clearbutton>
      </SignatureContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton disabled={isSubmitButtonDisabled} onClick={handleClick} />
      </ActionsArea>
    </>
  );
}
