import type { CSSProperties, ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import type AppPath from '../../../../interfaces/AppPath';

interface NavLinkProps extends ComponentProps<Link> {
  current?: boolean;
  disabled?: boolean;
  to: AppPath;
}

export default function NavLink({ current, disabled, to, ...props }: NavLinkProps) {
  const style: CSSProperties = {
    ...current && { textDecoration: 'underline', fontWeight: 'bold' },
    ...disabled
      ? { color: '#217ab550', cursor: 'not-allowed' }
      : { color: '#217ab5' }
  };

  return <Link {...props} style={style} to={disabled ? '#' : to} />;
}
