import { Steps } from 'antd';

import type { Activite } from '../../../../interfaces/ContratPret';
import type AppPath from '../../../../interfaces/AppPath';
import NavLink from './NavLink';
import NavSteps from './NavSteps';

interface NavDrawerContentProps {
  activites: Activite[];
  currentUrl: string;
  validatedFormRoutes: AppPath[];
}

export default function NavDrawerContent({ activites, currentUrl, validatedFormRoutes }: NavDrawerContentProps) {
  const sectionsSteps = [
    validatedFormRoutes.some(r => r.match('/contrat/patient')),
    validatedFormRoutes.some(r => r.match('/contrat/consentement')),
    validatedFormRoutes.some(r => r.match('/contrat/formation')),
    validatedFormRoutes.some(r => r.match('/contrat/signature'))
  ];

  const consentementSubSectionsSteps = [
    validatedFormRoutes.some(r => r.match('/contrat/consentement/materiel')),
    validatedFormRoutes.some(r => r.match('/contrat/consentement/documents')),
    validatedFormRoutes.some(r => r.match('/contrat/consentement/acceptations')),
    validatedFormRoutes.some(r => r.match('/contrat/consentement/decharge')),
    validatedFormRoutes.some(r => r.match('/contrat/consentement/information'))
  ];

  const patientSubSectionsSteps = [
    validatedFormRoutes.some(r => r.match('/contrat/patient/etat-civil')),
    validatedFormRoutes.some(r => r.match('/contrat/patient/couverture-sociale')),
    validatedFormRoutes.some(r => r.match('/contrat/patient/entourage')),
    validatedFormRoutes.some(r => r.match('/contrat/patient/referents'))
  ];

  return (
    <NavSteps current={sectionsSteps.filter(s => s).length - 1} direction="vertical" /* percent={100 / 4} */ size="small">
      <Steps.Step
        title={<NavLink to="/contrat/patient">Renseignements patient</NavLink>}
        subTitle={
          <Steps
            current={patientSubSectionsSteps.filter(s => s).length - (sectionsSteps[1] ? 0 : 1)}
            direction="vertical"
            size="small"
            style={{ marginTop: '25px' }}
          >
            <Steps.Step title={
              <NavLink
                current={!!currentUrl.match('/contrat/patient/etat-civil')}
                disabled={!patientSubSectionsSteps[0]}
                to="/contrat/patient/etat-civil"
              >
                Etat civil et coordonnées
              </NavLink>
            } />

            <Steps.Step title={
              <NavLink
                current={!!currentUrl.match('/contrat/patient/couverture-sociale')}
                disabled={!patientSubSectionsSteps[1]}
                to="/contrat/patient/couverture-sociale"
              >
                Couverture sociale
              </NavLink>
            } />

            <Steps.Step title={
              <NavLink
                current={!!currentUrl.match('/contrat/patient/entourage')}
                disabled={!patientSubSectionsSteps[2]}
                to="/contrat/patient/entourage"
              >
                Entourage
              </NavLink>
            } />

            <Steps.Step title={
              <NavLink
                current={!!currentUrl.match('/contrat/patient/referents')}
                disabled={!patientSubSectionsSteps[3]}
                to="/contrat/patient/referents"
              >
                Référents
              </NavLink>
            } />
          </Steps>
        }
      />

      <Steps.Step
        title={<NavLink disabled={!sectionsSteps[1]} to="/contrat/consentement">Consentement du patient</NavLink>}
        subTitle={
          <Steps
            current={consentementSubSectionsSteps.filter(s => s).length - (sectionsSteps[2] ? 0 : 1)}
            direction="vertical"
            size="small"
            style={{ marginTop: '25px' }}
          >
            <Steps.Step title={
              <NavLink
                current={!!currentUrl.match('/contrat/consentement/materiel')}
                disabled={!consentementSubSectionsSteps[0]} to="/contrat/consentement/materiel"
              >
                Remise du matériel
              </NavLink>
            } />

            <Steps.Step title={
              <NavLink
                current={!!currentUrl.match('/contrat/consentement/documents')}
                disabled={!sectionsSteps[1]}
                to="/contrat/consentement/documents"
              >
                Remise des documents
              </NavLink>
            } />

            <Steps.Step title={
              <NavLink
                current={!!currentUrl.match('/contrat/consentement/acceptations')}
                disabled={!consentementSubSectionsSteps[2]}
                to="/contrat/consentement/acceptations"
              >
                Acceptations du patient
              </NavLink>
            } />

            {['N', 'O', 'P', 'R'].some(activite => activites.includes(activite as Activite)) &&
              <Steps.Step title={
                <NavLink
                  current={!!currentUrl.match('/contrat/consentement/decharge')}
                  disabled={!consentementSubSectionsSteps[3]}
                  to="/contrat/consentement/decharge"
                >
                  Décharge
                </NavLink>
              } />
            }

            <Steps.Step title={
              <NavLink
                current={!!currentUrl.match('/contrat/consentement/information')}
                disabled={!consentementSubSectionsSteps[4]}
                to="/contrat/consentement/information"
              >
                Information
              </NavLink>
            } />
          </Steps>
        }
      />

      <Steps.Step title={
        <NavLink
          current={!!currentUrl.match('/contrat/formation')}
          disabled={!sectionsSteps[2]}
          to="/contrat/formation"
        >
          Formation et évaluation
        </NavLink>
      } />

      <Steps.Step title={
        <NavLink
          current={!!currentUrl.match('/contrat/signature')}
          disabled={!sectionsSteps[3]}
          to="/contrat/signature"
        >
          Signature
        </NavLink>
      } />
    </NavSteps>
  );
}
