import { Form } from 'antd';
import Input from '../Input';

const maxLength = 5;
const pattern = new RegExp('');
const placeholder = 'A';
const defaultErrorMessage = 'Veuillez saisir un bâtiment';
const defaultLabel = 'Bâtiment';
const defaultName = 'sBatiment';

interface FormItemBatimentProps {
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function FormItemBatiment({ disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = false }: FormItemBatimentProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <Input allowClear
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        size="large" />
    </Form.Item>
  );
}
