import styled, { keyframes } from 'styled-components';

export default styled.div<{ dark?: boolean; }>`
  animation: ${keyframes`to { opacity: 1; }`} .5s forwards;
  backdrop-filter: blur(5px);
  background-color: ${props => props.dark ? '#28516170' : '#edf3f470'};
  border-radius: 20px;
  box-shadow: #044493 0 5px 5px;
  color: ${props => props.dark ? 'white' : 'black'};
  opacity: 0;
  overflow-y: scroll;
  padding: 20px;
  scale: ${props => props.dark ? .99 : 1};
  scrollbar-width: none;
  transition-duration: .5s;
  transition-property: background-color, color, scale;
  width: 100%;
`;
