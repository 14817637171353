import { Checkbox } from 'antd';
import styled from 'styled-components';

export default styled(Checkbox)`
  --focus-hover-border-color: #044493;
  --mark-color: #217ab5;

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: var(--mark-color);
    border-radius: 1px;
  }

  .ant-checkbox-checked::after {
    border: none;
  }

  .ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: lightgrey;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--mark-color);
    border-radius: 2px;
  }

  .ant-checkbox-input:focus+.ant-checkbox-inner,
  .ant-checkbox-input:hover+.ant-checkbox-inner {
    border-color: var(--focus-hover-border-color);
  }

  .ant-checkbox-inner {
    background-color: white;
    border-radius: 3px;
    border-width: 0.5px;
    margin: 0 5px;
    transform: scale(calc(5/3));
  }
`;
