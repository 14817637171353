import { Form, Input } from 'antd';
import { useState } from 'react';

import { ActionsArea, BackButton, NextButton } from '../../../../components/buttons';
import type { Consentement, Patient, TypeSignataire } from '../../../../interfaces/ContratPret';
import { FormItemNom, FormItemPrenom, FormItemTypeSignataire } from '../../../../components/formItems';
import { default as Select, Option } from '../../../../components/Select';
import ContentCard from '../../../../components/ContentCard';

interface FormSignataireProps {
  consentement?: Consentement;
  patient?: Patient;
  onPreviousClick: () => void;
  onSubmit: ({ consentement }: { consentement: Consentement }) => void;
}

interface FormSignataireValues {
  procheIndex?: number;
  sNom?: string;
  sPrenom?: string;
  sCodeCivilite?: string;
  sTypeSignataire?: TypeSignataire;
}

export function FormSignataire({ consentement, onPreviousClick, onSubmit, patient }: FormSignataireProps) {
  const [typeSignataire, setTypeSignataire] = useState<TypeSignataire>(consentement?.signataire?.sTypeSignataire || 'Patient');
  const [form] = Form.useForm();

  const getRepresentantLegalIndex = () => {
    const index = patient!.entourage.findIndex((p) => p.sNom === consentement?.signataire?.sNom && p.sPrenom === consentement?.signataire?.sPrenom);

    return index < 0 ? undefined : index;
  };

  const handleChange = ({ sTypeSignataire }: FormSignataireValues) => {
    sTypeSignataire && setTypeSignataire(sTypeSignataire);
  };

  const handleSubmit = ({ procheIndex, sNom, sPrenom, sCodeCivilite, sTypeSignataire }: FormSignataireValues) =>
    onSubmit({
      consentement: {
        ...consentement,
        signataire: {
          sNom: sNom || patient?.entourage[procheIndex!].sNom,
          sPrenom: sPrenom || patient?.entourage[procheIndex!].sPrenom,
          sCodeCivilite: sCodeCivilite || patient?.entourage[procheIndex!]?.sCodeCivilite || undefined,
          sTypeSignataire,
        },
      },
    });

  return (
    <>
      <ContentCard>
        <Form form={form} name="formSignataire" onFinish={handleSubmit} onValuesChange={handleChange}>
          <FormItemTypeSignataire autoFocus initialValue={typeSignataire} label="Agissant en qualité de" required />
          <p>je soussigné(e),</p>
          {typeSignataire === 'Patient' ? (
            <>
              <FormItemNom disabled errorMessage="Veuillez saisir le nom du signataire" initialValue={patient?.etatCivil?.sNom} />

              <FormItemPrenom disabled errorMessage="Veuillez saisir le prénom du signataire" initialValue={patient?.etatCivil?.sPrenom} />

              <Form.Item initialValue={patient?.etatCivil?.sCodeCivilite} hidden name="sCodeCivilite">
                <Input hidden />
              </Form.Item>
            </>
          ) : (
            <Form.Item
              initialValue={getRepresentantLegalIndex()}
              name="procheIndex"
              rules={[{ message: 'Veuillez sélectionner un représentant légal', required: true }]}
            >
              <Select placeholder="Sélectionner un représentant" size="large">
                {patient?.entourage.map((proche, i) => (
                  <Option key={i} value={i}>
                    {`${proche.sNom?.toUpperCase()} ${proche.sPrenom}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          certifie qu’il m’a été remis ce jour...
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton form="formSignataire" htmlType="submit" />
      </ActionsArea>
    </>
  );
}
