import { Button, Form } from 'antd';
import {
  CheckOutlined, EditOutlined, UserAddOutlined, UserDeleteOutlined
} from '@ant-design/icons';
import type { ComponentProps } from 'react';
import { useState } from 'react';

import { ActionsArea, BackButton, NextButton } from '../../../../components/buttons';
import type { Entourage, Patient } from '../../../../interfaces/ContratPret';
import {
  FormItemAdresse,
  FormItemBatiment,
  FormItemCodePostal,
  FormItemCodeTypeVoie,
  FormItemCommune,
  FormItemEtage,
  FormItemGSM,
  FormItemInterphone,
  FormItemLienAvecPatient,
  FormItemNom,
  FormItemNumeroVoie,
  FormItemPorte,
  FormItemPrenom,
  FormItemTelephoneFixe
} from '../../../../components/formItems';
import ContentCard from '../../../../components/ContentCard';
import Drawer from '../../../../components/Drawer';

function AddButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      icon={<UserAddOutlined />}
      shape="round"
      size="large"
      style={{ backgroundColor: '#f0d80f', border: 'none', boxShadow: '#044493 0px 5px 5px', color: '#044493' }}
      type="primary"
    >
      Ajouter un proche
    </Button>
  );
}

function EditButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      icon={<EditOutlined />}
      shape="round"
      style={{ backgroundColor: '#f0d80f', border: 'none', color: '#044493', width: 'calc(50% - 10px)' }}
      type="primary"
    >
      Modifier
    </Button>
  );
}

function RemoveButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      danger
      icon={<UserDeleteOutlined />}
      shape="round"
      style={{ width: 'calc(50% - 10px)' }}
      type="primary"
    >
      Supprimer
    </Button>
  );
}

function RestoreButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      icon={<UserAddOutlined />}
      shape="round"
      style={{ backgroundColor: '#f0d80f', border: 'none', color: '#044493', width: '100%' }}
      type="primary"
    >
      Restaurer
    </Button>
  );
}

function SaveButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      icon={<CheckOutlined />}
      shape="round"
      style={{ backgroundColor: '#f0d80f', border: 'none', color: '#044493', width: '100%' }}
      type="primary"
    >
      Sauvegarder
    </Button>
  );
}

interface FormEntourageProps {
  entourage: Entourage;
  onSubmit: (newProche: Entourage) => void;
}

function FormEntourage({ entourage, onSubmit }: FormEntourageProps) {
  const handleSubmit = ({ sTelFixe, sTelGsm, ...rest }: Entourage) => onSubmit({
    ...rest,
    sTelFixe: sTelFixe?.replace(/\s/g, ''),
    sTelGsm: sTelGsm?.replace(/\s/g, '')
  });

  return (
    <Form name="formEntourage" onFinish={handleSubmit}>
      <FormItemNom
        autoFocus errorMessage="Veuillez saisir le nom du membre de l'entourage" initialValue={entourage?.sNom}
      />

      <FormItemPrenom
        errorMessage="Veuillez saisir le prénom du membre de l'entourage"
        initialValue={entourage?.sPrenom}
      />

      <FormItemLienAvecPatient initialValue={entourage?.sTypeLien} required />

      <div style={{ alignItems: 'start', display: 'grid', gridGap: '20px', gridTemplateColumns: 'repeat(2, 1fr)' }}>
        <FormItemNumeroVoie initialValue={entourage?.sNumeroVoie} />

        <FormItemCodeTypeVoie initialValue={entourage?.sCodeTypeVoie} />
      </div>

      <FormItemAdresse initialValue={entourage?.sAdresse} />

      <FormItemCodePostal initialValue={entourage?.sCodePostal} />

      <FormItemCommune initialValue={entourage?.sVille} />

      <FormItemGSM initialValue={entourage?.sTelGsm} />

      <FormItemTelephoneFixe initialValue={entourage?.sTelFixe} />

      <Form.Item style={{ margin: '20px 0' }}>
        <SaveButton htmlType="submit" />
      </Form.Item>
    </Form>
  )
}

interface EntourageItemProps {
  onEditClick: () => void;
  onRemoveToggle: () => void;
  entourage: Entourage;
}

function EntourageItem({ onEditClick, onRemoveToggle, entourage }: EntourageItemProps) {
  const handleClick = () => onRemoveToggle();

  return (
    <ContentCard dark={entourage?.bSupprime} style={{ marginBottom: '20px' }}>
      <div style={{ marginBottom: '15px', textAlign: 'center' }}>
        {`${entourage.sNom?.toUpperCase()} ${entourage.sPrenom ? entourage.sPrenom : ''} `}
        <span style={{ fontSize: 'smaller' }}>{entourage.sTypeLien ? `(${entourage.sTypeLien?.toLowerCase()})` : ''}</span>
      </div>

      {entourage.bSupprime
        ? <RestoreButton onClick={handleClick} />
        : <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <RemoveButton onClick={handleClick} />
          <EditButton onClick={onEditClick} />
        </div>
      }
    </ ContentCard >
  );
}

interface SectionEntourageProps {
  onPreviousClick: () => void;
  onSubmit: ({ patient }: { patient: Patient }) => void;
  patient: Patient;
}

export default function SectionEntourage({ onPreviousClick, onSubmit, patient }: SectionEntourageProps) {
  const [entourage, setEntourage] = useState(patient.entourage);
  const [EntourageIndexBeingEdited, setEntourageIndexBeingEdited] = useState<number>();

  const closeDrawer = () => setEntourageIndexBeingEdited(undefined);

  const handleProcheUpdate = (procheIndex: number, updatedProcheData: Entourage) => {
    setEntourage(entourage => {
      entourage.splice(procheIndex, 1, { ...entourage[procheIndex], ...updatedProcheData });
      const sortedEntourage = entourage.sort((a: any, b: any) => {
        if (a.sNom > b.sNom) {
          return 1;
        }
        if (a.sNom < b.sNom) {
          return -1;
        }
        return 0;
      });
      return [...sortedEntourage];
    });
  };

  const handleSubmit = () => onSubmit({
    patient: { ...patient, entourage: entourage?.filter(p => 'iPKAnnuaire' in p || !p.bSupprime) }
  });

  return (
    <>
      <div style={{ height: '100%', overflowY: 'scroll', scrollbarWidth: 'none', width: '100%' }}>
        <Drawer
          bodyStyle={{ backgroundColor: '#edf3f4' }}
          destroyOnClose
          footerStyle={{ backgroundColor: '#edf3f4' }}
          headerStyle={{ backgroundColor: '#edf3f4' }}
          onClose={closeDrawer}
          placement="right"
          title={EntourageIndexBeingEdited && EntourageIndexBeingEdited > entourage.length
            ? 'Ajout d\'un proche' : 'Mise à jour d\'un proche'
          }
          visible={EntourageIndexBeingEdited !== undefined}
        >
          {EntourageIndexBeingEdited !== undefined && <FormEntourage
            entourage={entourage[EntourageIndexBeingEdited]}
            onSubmit={updatedProcheData => { handleProcheUpdate(EntourageIndexBeingEdited, updatedProcheData); closeDrawer(); }}
          />}
        </Drawer>

        {entourage.map((proche, i) => <EntourageItem
          entourage={proche}
          key={i}
          onEditClick={() => setEntourageIndexBeingEdited(i)}
          onRemoveToggle={() => handleProcheUpdate(i, { bSupprime: !proche.bSupprime })}
        />)}

        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <AddButton onClick={() => setEntourageIndexBeingEdited(entourage.length + 1)} />
        </div>
      </div>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton onClick={handleSubmit} />
      </ActionsArea>
    </>
  );
}
