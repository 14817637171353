import { Form } from 'antd';
import TextArea from '../TextArea';

const maxLength = 10000;
const pattern = new RegExp('');
const placeholder = 'Raison de la suppression';
const defaultErrorMessage = 'Veuillez saisir la raison de la suppression';
const defaultLabel = 'Raison de la suppression';
const defaultName = 'sRaisonSuppression';

interface FormItemRaisonSuppressionProps {
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
  onChange?: Function;
}

export default function FormItemRaisonSuppression({ onChange, disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = false }: FormItemRaisonSuppressionProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      normalize={(nom: string) => nom.replace(/^./g, firstLetter => firstLetter.toUpperCase())}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <TextArea allowClear
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={onChange}
        size="large" />
    </Form.Item>
  );
}
