import { Button, Layout } from 'antd';
import { default as styled, keyframes } from 'styled-components';
import { MenuUnfoldOutlined } from '@ant-design/icons';

const BurgerButton = styled(Button)`
  border: none;
  /* color: #edf3f4; */
  height: 100%;
  position: absolute;

  :focus, :hover {
    border: none;
    color: black;
    /* color: #edf3f4; */
  }
`;

const LayoutHeader = styled(Layout.Header)`
  animation: ${keyframes`to { transform: translate(0, 0); }`} 0.5s forwards;
  /* background-color: #217ab5; */
  background-color: #edf3f4;
  height: auto;
  line-height: unset;
  min-height: 64px;
  padding: 0;
  position: relative;
  transform: translate(0, -100%);
  width: 100%;
`;

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;

  h3, h4 {
    /* color: #edf3f4; */
    margin: 0;
  }
`;

const Section = styled.h3``;

const SubSection = styled.h4``;

interface HeaderProps {
  openNavDrawer: () => void;
  section: string;
  subSection?: string;
}

export default function Header({ openNavDrawer, section, subSection }: HeaderProps) {
  return (
    <LayoutHeader>
      <BurgerButton onClick={openNavDrawer} size="large" type="ghost">
        <MenuUnfoldOutlined />
      </BurgerButton>

      <Titles>
        <Section>{section}</Section>
        {subSection && <SubSection>{subSection}</SubSection>}
      </Titles>
    </LayoutHeader>
  );
}
