import { Form, Row, Space } from "antd";
import { useState, useEffect } from "react";

import {
  ActionsArea,
  BackButton,
  NextButton,
} from "../../../../components/buttons";
import type { EtatCivil, Patient } from "../../../../interfaces/ContratPret";
import {
  FormItemAdresse,
  FormItemAssuranceNom,
  FormItemAssurancePolice,
  FormItemBatiment,
  FormItemCodePostal,
  FormItemCodeTypeVoie,
  FormItemCommune,
  FormItemCourriel,
  FormItemDateNaissance,
  FormItemEtage,
  FormItemGSM,
  FormItemInterphone,
  FormItemNom,
  FormItemNumeroVoie,
  FormItemPorte,
  FormItemPrenom,
  FormItemTelephoneFixe,
} from "../../../../components/formItems";
import type Adresse from "../../../../interfaces/ContratPret/Adresse";
import Checkbox from "../../../../components/Checkbox";
import ContentCard from "../../../../components/ContentCard";
import Input from "../../../../components/Input";

interface FormAdresseProps {
  onPreviousClick: () => void;
  onSubmit: ({ patient }: { patient: Patient }) => void;
  patient: Patient;
}

export function FormAdresse({
  onPreviousClick,
  onSubmit,
  patient,
}: FormAdresseProps) {
  const handleSubmit = (adresse: Adresse) =>
    onSubmit({
      patient: { ...patient, etatCivil: { ...patient.etatCivil, ...adresse } },
    });

  return (
    <>
      <ContentCard>
        <Form name="formAdresse" onFinish={handleSubmit}>
          <div
            style={{
              display: "grid",
              gridGap: "20px",
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            <FormItemNumeroVoie
              errorMessage="Veuillez saisir le numéro de voie du patient"
              initialValue={patient.etatCivil?.sNumeroVoie}
            />

            <FormItemCodeTypeVoie
              errorMessage="Veuillez sélectionner le type de voie du patient"
              initialValue={patient.etatCivil?.sCodeTypeVoie}
            />
          </div>

          <FormItemAdresse
            errorMessage="Veuillez saisir l'adresse du patient"
            initialValue={patient.etatCivil?.sAdresse}
            required
          />

          <div
            style={{
              display: "grid",
              gridColumnGap: "20px",
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            <FormItemBatiment initialValue={patient.etatCivil?.sBatiment} />

            <FormItemEtage initialValue={patient.etatCivil?.sEtage} />

            <FormItemPorte initialValue={patient.etatCivil?.sPorte} />

            <FormItemInterphone initialValue={patient.etatCivil?.sInterphone} />
          </div>

          <FormItemCodePostal
            errorMessage="Veuillez saisir le code postal du patient"
            initialValue={patient.etatCivil?.sCodePostal}
            required
          />

          <FormItemCommune
            errorMessage="Veuillez saisir la commune du patient"
            initialValue={patient.etatCivil?.sVille}
            required
          />
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          form="formAdresse"
          /* form cancels centering style */ htmlType="submit"
        />
      </ActionsArea>
    </>
  );
}

interface FormAssuranceProps {
  onPreviousClick: () => void;
  onSubmit: ({ patient }: { patient: Patient }) => void;
  patient: Patient;
}

type FormAssuranceValues = Required<
  Pick<
    EtatCivil,
    | "bPoliceAssuranceNonCommuniquee"
    | "bAssuranceNonCommuniquee"
    | "sAssurance"
    | "sPoliceAssurance"
  >
>;

export function FormAssurance({
  onPreviousClick,
  onSubmit,
  patient,
}: FormAssuranceProps) {
  const handleSubmit = (AssuranceValues: FormAssuranceValues) =>
    onSubmit({
      patient: {
        ...patient,
        etatCivil: { ...patient.etatCivil, ...AssuranceValues },
      },
    });

  const [bAssuranceNonCommuniquee, setbAssuranceNonCommuniquee] = useState(
    patient.etatCivil?.bAssuranceNonCommuniquee
  );
  const [bPoliceAssuranceNonCommuniquee, setbPoliceAssuranceNonCommuniquee] =
    useState(patient.etatCivil?.bPoliceAssuranceNonCommuniquee);

  return (
    <>
      <ContentCard>
        <Form name="formAssurance" onFinish={handleSubmit}>
          <FormItemAssuranceNom
            autoFocus
            required={!bAssuranceNonCommuniquee}
            disabled={bAssuranceNonCommuniquee}
            initialValue={patient.etatCivil?.sAssurance}
          />
          <Form.Item
            initialValue={patient.etatCivil?.bAssuranceNonCommuniquee}
            name="bAssuranceNonCommuniquee"
            valuePropName="checked"
          >
            <Checkbox
              defaultChecked={patient.etatCivil?.bAssuranceNonCommuniquee}
              onChange={(e) => setbAssuranceNonCommuniquee(e.target.checked)}
            >
              Information non communiquée
            </Checkbox>
          </Form.Item>
          <FormItemAssurancePolice
            required={!bPoliceAssuranceNonCommuniquee}
            disabled={bPoliceAssuranceNonCommuniquee}
            initialValue={patient.etatCivil?.sPoliceAssurance}
          />
          <Form.Item
            initialValue={patient.etatCivil?.bPoliceAssuranceNonCommuniquee}
            name="bPoliceAssuranceNonCommuniquee"
            valuePropName="checked"
          >
            <Checkbox
              defaultChecked={patient.etatCivil?.bPoliceAssuranceNonCommuniquee}
              onChange={(e) =>
                setbPoliceAssuranceNonCommuniquee(e.target.checked)
              }
            >
              Information non communiquée
            </Checkbox>
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          form="formAssurance"
          /* form cancels centering style */ htmlType="submit"
        />
      </ActionsArea>
    </>
  );
}

interface FormContactProps {
  bPasDeMail?: boolean;
  bPasDeGsm?: boolean;
  onPreviousClick: () => void;
  onSubmit: ({ patient }: { patient: Patient }) => void;
  patient: Patient;
}

type FormContactValues = Required<
  Pick<EtatCivil, "bPasDeMail" | "bPasDeGsm" | "sMail" | "sTelFixe" | "sTelGsm">
>;

export function FormContact({
  onPreviousClick,
  onSubmit,
  patient,
}: FormContactProps) {
  const [form] = Form.useForm();
  const [noEmail, setNoEmail] = useState(patient.etatCivil?.bPasDeMail);
  const [noGsm, setNoGsm] = useState(patient.etatCivil?.bPasDeGsm);

  const handleSubmit = ({ sTelFixe, sTelGsm, ...rest }: FormContactValues) =>
    onSubmit({
      patient: {
        ...patient,
        etatCivil: {
          ...patient.etatCivil,
          ...rest,
          sTelFixe: sTelFixe?.replace(/\s/g, ""),
          sTelGsm: sTelGsm?.replace(/\s/g, ""),
        },
      },
    });

  useEffect(() => {
    form.validateFields(["sTelGsm"]);
  }, [noGsm, form]);

  return (
    <>
      <ContentCard>
        <Form form={form} name="formContact" onFinish={handleSubmit}>
          <FormItemGSM
            disabled={noGsm}
            errorMessage={
              noGsm ? "" : "Veuillez saisir le téléphone portable du patient"
            }
            required={!noGsm}
            initialValue={patient.etatCivil?.sTelGsm}
          />
          <Form.Item
            initialValue={patient.etatCivil?.bPasDeGsm}
            name="bPasDeGsm"
            valuePropName="checked"
          >
            <Checkbox
              defaultChecked={false}
              onChange={(e) => setNoGsm(e.target.checked)}
            >
              Pas de téléphone portable
            </Checkbox>
          </Form.Item>

          <FormItemTelephoneFixe initialValue={patient.etatCivil?.sTelFixe} />

          <FormItemCourriel
            disabled={noEmail}
            errorMessage="Veuillez saisir le courriel du patient"
            initialValue={patient.etatCivil?.sMail}
            required={!noEmail}
          />

          <Form.Item
            initialValue={patient.etatCivil?.bPasDeMail}
            name="bPasDeMail"
            valuePropName="checked"
          >
            <Checkbox
              defaultChecked={patient.etatCivil?.bPasDeMail}
              onChange={(e) => setNoEmail(e.target.checked)}
            >
              Pas de courriel
            </Checkbox>
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          form="formContact"
          /* form cancels centering style */ htmlType="submit"
        />
      </ActionsArea>
    </>
  );
}

interface FormNaissanceProps {
  onPreviousClick: () => void;
  onSubmit: ({ patient }: { patient: Patient }) => void;
  patient: Patient;
}

interface FormNaissanceValues {
  sDateNaissance: string;
  sLieuNaissance: string;
}

export function FormNaissance({
  onPreviousClick,
  onSubmit,
  patient,
}: FormNaissanceProps) {
  const handleSubmit = ({
    sDateNaissance,
    sLieuNaissance,
  }: FormNaissanceValues) =>
    onSubmit({
      patient: {
        ...patient,
        etatCivil: {
          ...patient.etatCivil,
          dDateNaissance: new Date(sDateNaissance),
          sLieuNaissance,
        },
      },
    });

  return (
    <>
      <ContentCard>
        <Form name="formNaissance" onFinish={handleSubmit}>
          <FormItemDateNaissance
            autoFocus
            errorMessage="Veuillez saisir la date de naissance du patient"
            initialValue={patient.etatCivil?.dDateNaissance}
            required
          />

          <FormItemCommune
            errorMessage="Veuillez saisir la commune de naissance du patient"
            initialValue={patient.etatCivil?.sLieuNaissance}
            label="Commune de naissance"
            name="sLieuNaissance"
            required
          />
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          form="formNaissance"
          /* form cancels centering style */ htmlType="submit"
        />
      </ActionsArea>
    </>
  );
}

interface FormNomsProps {
  onSubmit: ({ patient }: { patient: Patient }) => void;
  patient: Patient;
}

type FormNomsValues = Pick<EtatCivil, "sNomJeuneFille"> &
  Required<Pick<EtatCivil, "sNom" | "sPrenom">>;

export function FormNoms({ onSubmit, patient }: FormNomsProps) {
  const handleSubmit = (noms: FormNomsValues) =>
    onSubmit({
      patient: { ...patient, etatCivil: { ...patient.etatCivil, ...noms } },
    });

  return (
    <>
      <ContentCard>
        <Form name="formNoms" onFinish={handleSubmit}>
          <FormItemNom
            autoFocus
            initialValue={patient.etatCivil?.sNom}
            errorMessage="Veuillez saisir le nom du patient"
          />

          <FormItemPrenom
            initialValue={patient.etatCivil?.sPrenom}
            errorMessage="Veuillez saisir le prénom du patient"
          />

          <FormItemNom
            initialValue={patient.etatCivil?.sNomJeuneFille}
            label="Nom de jeune fille"
            name="sNomJeuneFille"
            required={false}
          />
        </Form>
      </ContentCard>

      <ActionsArea>
        <NextButton
          form="formNoms"
          /* form cancels centering style */ htmlType="submit"
        />
      </ActionsArea>
    </>
  );
}

interface FormVerificationIdentiteProps {
  onPreviousClick: () => void;
  onSubmit: ({ patient }: { patient: Patient }) => void;
  patient: Patient;
}

interface FormVerificationIdentiteValues {
  supportsVerificationIdentite: NonNullable<
    EtatCivil["supportsVerificationIdentite"]
  >;
}

export function FormVerificationIdentite({
  onPreviousClick,
  onSubmit,
  patient,
}: FormVerificationIdentiteProps) {
  const [autreSupport, setAutreSupport] = useState(
    patient.etatCivil?.autreSupport
  );
  const [autreSupportChecked, setAutreSupportChecked] = useState(
    patient.etatCivil?.supportsVerificationIdentite?.includes("autre")
  );

  const initialValue = [
    autreSupportChecked && "autre",
    patient.etatCivil?.supportsVerificationIdentite?.includes("cni") && "cni",
    patient.etatCivil?.supportsVerificationIdentite?.includes("passeport") &&
      "passeport",
  ].filter((support) => support);

  const handleSubmit = ({
    supportsVerificationIdentite,
  }: FormVerificationIdentiteValues) =>
    onSubmit({
      patient: {
        ...patient,
        etatCivil: {
          ...patient.etatCivil,
          autreSupport,
          supportsVerificationIdentite,
        },
      },
    });

  return (
    <>
      <ContentCard>
        <Form
          layout="vertical"
          name="formVerificationIdentite"
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Pièce(s) d'identité vérifiée(s)"
            initialValue={initialValue}
            name="supportsVerificationIdentite"
            rules={[
              {
                message: "Veuillez choisir au moins un support",
                required: true,
              },
            ]}
          >
            <Checkbox.Group>
              <Row style={{ margin: "10px 0 20px" }}>
                <Checkbox autoFocus value="cni">
                  Carte d'identité
                </Checkbox>
              </Row>

              <Row style={{ marginBottom: "20px" }}>
                <Checkbox value="passeport">Passeport</Checkbox>
              </Row>

              <Row style={{ alignItems: "center", display: "flex" }}>
                <Space>
                  <Checkbox
                    indeterminate={autreSupportChecked && !autreSupport}
                    onChange={(e) => {
                      e.target.checked || setAutreSupport(undefined);
                      setAutreSupportChecked(e.target.checked);
                    }}
                    value="autre"
                  >
                    Autre
                  </Checkbox>

                  {autreSupportChecked && (
                    <Form.Item
                      initialValue={autreSupport}
                      label="Autre"
                      name="autreSupport"
                      noStyle
                      rules={[
                        {
                          message:
                            "Veuillez renseigner l'autre type de support utilisé",
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        autoFocus
                        onChange={(e) => setAutreSupport(e.target.value)}
                        placeholder="Permis de conduire"
                        size="small"
                      />
                    </Form.Item>
                  )}
                </Space>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton
          form="formVerificationIdentite"
          /* form cancels centering style */ htmlType="submit"
        />
      </ActionsArea>
    </>
  );
}
