import type { ComponentProps } from 'react';
import { Select as AntdSelect } from 'antd';
import styled from 'styled-components';

const SelectWrapper = styled.div`
  .ant-select-selector {
    border-radius: 10px !important;

    :focus-within {
      border-color: #044493 !important;
    }
  }
`;

type SelectProps = ComponentProps<typeof AntdSelect>;

export default function Select({ children, dropdownStyle, ...props }: SelectProps) {
  return (
    <SelectWrapper>
      <AntdSelect
        {...props}
        dropdownStyle={{ borderRadius: '10px', ...dropdownStyle }}
      >
        {children}
      </AntdSelect>
    </SelectWrapper >
  );
}

export const OptGroup = AntdSelect.OptGroup;

export const Option = AntdSelect.Option;
