import { Form } from 'antd';
import type { ValidatorRule } from 'rc-field-form/lib/interface';
import Input from '../Input';

const placeholder = 'jj/mm/aaaa';
const defaultErrorMessage = 'Veuillez saisir une date de naissance';
const defaultLabel = 'Date de naissance';
const defaultName = 'sDateNaissance';

interface FormItemDateNaissanceProps {
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: Date | string;
  label?: string;
  name?: string;
  required?: boolean;
}

const priorDateValidator: ValidatorRule['validator'] = async (_, v: string) => {
  if (new Date(v) >= new Date()) throw new Error('Veuillez saisir une date antérieure à la date du jour')
};

export default function FormItemDateNaissance({ autoFocus, disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = false }: FormItemDateNaissanceProps) {
  return (
    <Form.Item
      initialValue={initialValue instanceof Date ? initialValue.toISOString().slice(0, 10) : initialValue}
      label={label}
      name={name}
      rules={[{ message: errorMessage, required, type: 'date' }, { validator: priorDateValidator }]}
    >
      <Input
        autoFocus={autoFocus}
        disabled={disabled}
        placeholder={placeholder}
        size="large"
        type="date"
      />
    </Form.Item>
  );
}
