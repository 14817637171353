import "antd/dist/antd.css";
import { Provider } from "react-redux";
import { StrictMode } from "react";
import { parse as parseUrlQuery } from "querystring";
import { render } from "react-dom";
import "./index.css";
import { authentificationAction } from "./redux/utilisateur/actions/authentification.action";
import App from "./App";
import store from "./store";

store.dispatch<any>(authentificationAction());

render(
  <StrictMode>
    <Provider store={store}>
      <App parsedUrlQuery={parseUrlQuery(window.location.search.slice(1))} />
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);
