import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import type { ParsedUrlQuery } from "querystring";
import { message } from "antd";

import { selectUtilisateur } from "./redux/utilisateur/utilisateur.slice";

import RedirectComponent from "./RedirectComponent";

import { getPatient } from "./api/patient";
import type ContratPret from "./interfaces/ContratPret";
import ContratView from "./views/Contrat";
import HomeView from "./views/Home";
import InvalidEnv from "./views/InvalidEnv";
import LoginView from "./views/Login";
import NotFoundView from "./views/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import SendingSuccessView from "./views/SendingSuccess";
import SendingView from "./views/Sending";
import validateEnv from "./utils/validateEnv";
import NotAllowedView from "./views/NotAllowed";
// import { authentificationAction } from "./redux/utilisateur/actions/authentification.action";

const emptyContrat = {
  activites: [],
  formation: { personnesFormees: [] },
  patient: { entourage: [], referents: [], etatCivil: {} },
  status: "new",
};

interface AppProps {
  parsedUrlQuery: ParsedUrlQuery;
}

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event) => {
    // Show prompt based on state
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }

      return "";
    }
  };
};

const isValidContrat = (contrat: ContratPret) => {
  return (
    contrat?.intervenant &&
    (contrat?.patient?.etatCivil?.iPKAnnuaire || contrat?.sIDVisite)
  );
};

export default function App({ parsedUrlQuery }: AppProps) {
  const user = useSelector(selectUtilisateur);
  const [contrat, setContrat] = useState<ContratPret>(emptyContrat);
  const showExitPrompt = true;

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    if (user) {
      if (
        typeof parsedUrlQuery.iPKAnnuaire === "string" &&
        parsedUrlQuery.iPKAnnuaire.length > 0
      ) {
        getPatient(parsedUrlQuery.iPKAnnuaire)
          .then((patient) => {
            document.cookie = `contrat=${JSON.stringify({
              ...emptyContrat,
              patient,
              intervenant: user,
            })}; path=/; SameSite=strict; Secure;`;
            setContrat({ ...emptyContrat, patient, intervenant: user });
          })
          .catch((error) => {
            message.error(
              `Echec de la récupération des informations du patient (${error.message})`,
              5
            );
          });
      } else if (Object.keys(parsedUrlQuery).length > 0) {
        const patient = JSON.parse(
          Buffer.from(parsedUrlQuery.patient as string, "base64").toString()
        );
        const {
          sAdresse,
          sCodeCivilite,
          sCodePostal,
          sCodeTypeVoie,
          sNom,
          sNumeroVoie,
          sPrenom,
          sSexe,
          sVille,
          sTelFixe,
          sTelGSM,
          sIDVisite,
        } = patient;
        const contratJson = {
          ...emptyContrat,
          sIDVisite,
          intervenant: user,
          patient: {
            ...emptyContrat.patient,
            etatCivil: {
              sCodeCivilite,
              sNom,
              sPrenom,
              sSexe,
              sTelFixe,
              sTelGsm: sTelGSM,
              sAdresse,
              sCodePostal,
              sCodeTypeVoie,
              sNumeroVoie,
              sVille,
            },
          },
        };
        document.cookie = `contrat=${JSON.stringify(
          contratJson
        )}; path=/; SameSite=strict; Secure;`;
        setContrat(contratJson);
      } else if (document.cookie.match(`(^|;) ?contrat=([^;]*)(;|$)`)) {
        const cookie = document.cookie.match(`(^|;) ?contrat=([^;]*)(;|$)`);

        if (cookie) {
          const contratInCookie = cookie.length >= 3 ? cookie[2] : null;
          if (contratInCookie && isValidContrat(JSON.parse(contratInCookie))) {
            setContrat(JSON.parse(contratInCookie));
          }
        }
      }
    }
  }, [parsedUrlQuery, user]);

  try {
    validateEnv();
  } catch (error) {
    return <InvalidEnv />;
  }

  function getCookie(name: string) {
    function escape(s: string) {
      return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, "\\$1");
    }

    const match = document.cookie.match(
      RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)")
    );

    return match ? match[1] : null;
  }

  function restart(contrat: ContratPret) {
    if (!isValidContrat(contrat)) {
      const cookieContrat = getCookie("contrat");

      if (cookieContrat && isValidContrat(JSON.parse(cookieContrat))) {
        return <ContratView preFilledContrat={JSON.parse(cookieContrat)} />;
      }

      return <Redirect to="/not-allowed" />;
    }

    return <ContratView preFilledContrat={contrat} />;
  }

  return (
    <Router>
      <RedirectComponent />
      <Switch>
        <Route exact path="/" component={HomeView} />
        <ProtectedRoute exact path="/contrat/envoi" component={SendingView} />
        <Route
          exact
          path="/contrat/envoi/succes"
          component={SendingSuccessView}
        />
        <ProtectedRoute path="/contrat" render={() => restart(contrat)} />
        <Route exact path="/connexion" component={LoginView} />
        <Route exact path="/not-allowed" component={NotAllowedView} />
        <Route component={NotFoundView} />
      </Switch>
    </Router>
  );
}
