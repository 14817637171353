import { Form, Radio, Space } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { ActionsArea, BackButton, NextButton } from '../../../../components/buttons';
import type { Consentement } from '../../../../interfaces/ContratPret';
import ContentCard from '../../../../components/ContentCard';


const Title = styled.h3`
  text-align: center;
`;

interface FormDechargeProps {
  consentement?: Consentement;
  onPreviousClick: () => void;
  onSubmit: (values: any) => void;
}

export function FormDecharge({ consentement, onPreviousClick, onSubmit }: FormDechargeProps) {
  const [toggleValue, setToggle] = useState(consentement?.accords?.bDecharge);

  const handleSubmit = () => onSubmit({
    consentement: {
      ...consentement,
      accords: {
        ...consentement?.accords,
        bDecharge: toggleValue
      }
    }
  });

  return (
    <>
      <ContentCard>
        <Title>Le signataire décharge&nbsp;:</Title>

        <Form>
          Santélys de tout incident ou accident pouvant survenir
          avec l'appareillage du fait de l'installation électrique

          <Form.Item style={{ margin: '0 0 0 5px', marginTop: 15, width: '100%' }}>
            <Radio.Group style={{ width: '100%' }} onChange={(event) => setToggle(event.target.value)} value={toggleValue}>
              <Space direction="vertical" style={{ gap: 15, width: '100%' }}>
                <Radio value={true}>Oui</Radio>
                <Radio value={false}>Non concerné</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton disabled={toggleValue === undefined} onClick={handleSubmit} />
      </ActionsArea>
    </>
  );
}
