import { Form } from 'antd';
import Input from '../Input';

const maxLength = 1000; // TODO
const pattern = new RegExp('');
const placeholder = 'Nom de la commune';
const defaultErrorMessage = 'Veuillez saisir le nom d\'une commune';
const defaultLabel = 'Commune';
const defaultName = 'sVille';

interface FormItemCommuneProps {
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

function normalize(commune: string) {
  return commune.replace(
    /[a-zA-ZÀ-ÖØ-öø-ÿ]+/g,
    s => s[0].toUpperCase() + s.slice(1)
  );
}

export default function FormItemCommune({ autoFocus, disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = false }: FormItemCommuneProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      normalize={normalize}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <Input
        allowClear
        autoCapitalize="words"
        autoFocus={autoFocus}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        size="large"
      />
    </Form.Item>
  );
}
