import { Form } from 'antd';
import TextArea from '../TextArea';

const maxLength = 1000; // TODO
const pattern = new RegExp('');
const placeholder = 'Nom de la voie';
const defaultErrorMessage = 'Veuillez saisir une adresse';
const defaultLabel = 'Voie';
const defaultName = 'sAdresse';

interface FormItemAdresseProps {
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function FormItemAdresse({ disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = false }: FormItemAdresseProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      normalize={(nom: string) => nom.replace(/^./g, firstLetter => firstLetter.toUpperCase())}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <TextArea allowClear
        disabled={disabled}
        maxLength={maxLength} // TODO
        placeholder={placeholder}
        size="large" />
    </Form.Item>
  );
}
