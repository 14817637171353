import { Form, Divider } from 'antd';
import { useState } from 'react';
import { useConfig } from '../../../../../components/ConfigContext';
import { ActionsArea, BackButton, NextButton } from '../../../../../components/buttons';
import {
  FormItemAdresse,
  FormItemCodePostal,
  FormItemCodeTypeVoie,
  FormItemCommune,
  FormItemNomOrganismeComplementaire,
  FormItemNumeroAdherent,
  FormItemNumeroVoie,
  SelectFormItemNomOrganismeComplementaire,
} from '../../../../../components/formItems';
import type { Patient } from '../../../../../interfaces/ContratPret';
import ContentCard from '../../../../../components/ContentCard';
import Switch from '../../../../../components/Switch';

interface FormAdresseMutuelleProps {
  onPreviousClick: () => void;
  onSubmit: ({ patient }: { patient: Patient }) => void;
  patient: Patient;
}

interface FormAdresseMutuelleValues {
  sAdresse?: string;
  sCodePostal?: string;
  sCodeTypeVoie?: string;
  sNumeroVoie?: string;
  sVille: string;
}

export function FormAdresseMutuelle({ onPreviousClick, onSubmit, patient }: FormAdresseMutuelleProps) {
  const handleSubmit = (adresse: FormAdresseMutuelleValues) =>
    onSubmit({
      patient: {
        ...patient,
        couvertureSociale: {
          ...patient.couvertureSociale,
          regimeComplementaire: {
            ...patient.couvertureSociale?.regimeComplementaire,
            ...adresse,
          },
        },
      },
    });

  return (
    <>
      <ContentCard>
        <Form name="formAdresseMutuelle" onFinish={handleSubmit}>
          <div style={{ alignItems: 'start', display: 'grid', gridGap: '20px', gridTemplateColumns: 'repeat(2, 1fr)' }}>
            <FormItemNumeroVoie autoFocus initialValue={patient.couvertureSociale?.regimeComplementaire?.sNumeroVoie} />

            <FormItemCodeTypeVoie initialValue={patient.couvertureSociale?.regimeComplementaire?.sCodeTypeVoie} />
          </div>

          <FormItemAdresse initialValue={patient.couvertureSociale?.regimeComplementaire?.sAdresse} />

          <FormItemCodePostal initialValue={patient.couvertureSociale?.regimeComplementaire?.sCodePostal} />

          <FormItemCommune
            errorMessage="Veuillez saisir la commune de la personne assurée"
            initialValue={patient.couvertureSociale?.regimeComplementaire?.sVille}
            required
          />
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton form="formAdresseMutuelle" /* form cancels centering style */ htmlType="submit" />
      </ActionsArea>
    </>
  );
}

interface FormSouscriptionMutuelleProps {
  onPreviousClick: () => void;
  onSubmit: ({ patient }: { patient: Patient }) => void;
  patient: Patient;
}

interface FormSouscriptionMutuelleValues {
  sNom?: string;
  sNoAdherent?: string;
}

export function FormSouscriptionMutuelle({ onPreviousClick, onSubmit, patient }: FormSouscriptionMutuelleProps) {
  const [hasMutuelle, setHasMutuelle] = useState(!patient.couvertureSociale?.regimeObligatoire?.bPasMutuelle);
  const [form] = Form.useForm();
  const config = useConfig();

  const handleChangeSelect = () => {
    form.setFieldsValue(
      config?.tOrganismeComplementaire.find((c) => `${c.sNom} (${c.sCodeAnnuaire})` === form.getFieldValue('sSelectNom')) || {
        sAdresse: undefined,
        sCodePostal: undefined,
        sCodeTypeVoie: undefined,
        sNom: undefined,
        sNumeroVoie: undefined,
        sTelFixe: undefined,
        sVille: undefined,
        sCodeAnnuaire: undefined
      }
    );
  };

  const handleSubmit = ({ sNom, sNoAdherent }: FormSouscriptionMutuelleValues) => {
    onSubmit({
      patient: {
        ...patient,
        couvertureSociale: {
          ...patient.couvertureSociale,
          regimeComplementaire: {
            ...patient.couvertureSociale?.regimeComplementaire,
            ...config?.tOrganismeComplementaire.find((c) => `${c.sNom} (${c.sCodeAnnuaire})` === form.getFieldValue('sSelectNom')) || {
              sAdresse: undefined,
              sCodePostal: undefined,
              sCodeTypeVoie: undefined,
              sNom: undefined,
              sNumeroVoie: undefined,
              sTelFixe: undefined,
              sVille: undefined,
              sCodeAnnuaire: undefined
            },
            sNom,
            sNoAdherent
          },
          regimeObligatoire: {
            ...patient.couvertureSociale?.regimeObligatoire,
            bPasMutuelle: !hasMutuelle,
          },
        },
      },
    });
  }

  return (
    <>
      <ContentCard>
        <Form form={form} name="formSouscriptionMutuelle" onFinish={handleSubmit}>
          <Form.Item initialValue={hasMutuelle} style={{ marginBottom: hasMutuelle ? undefined : 0 }} valuePropName="checked">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              L'assuré a une mutuelle&nbsp;?
              <Switch
                autoFocus
                checked={hasMutuelle}
                checkedChildren="Oui"
                defaultChecked={hasMutuelle}
                onChange={setHasMutuelle}
                unCheckedChildren="Non"
              />
            </div>
          </Form.Item>

          {hasMutuelle ? (
            <>
              <div style={{ fontWeight: 'bold', marginBottom: '20px', textAlign: 'justify' }}>Fournir une copie de la carte de mutuelle</div>
              <SelectFormItemNomOrganismeComplementaire
                autoFocus
                errorMessage="Veuillez saisir le nom de la caisse de sécurité sociale de la personne assurée"
                onChange={handleChangeSelect}
                initialValue={
                  patient.couvertureSociale?.regimeComplementaire?.sNom
                    ? `${patient.couvertureSociale?.regimeComplementaire?.sNom} ${patient.couvertureSociale?.regimeComplementaire?.sCodeAnnuaire ? `(${patient.couvertureSociale?.regimeComplementaire?.sCodeAnnuaire})` : ''}`
                    : undefined
                }
              />
              <div>
                <Divider style={{ borderColor: 'white', fontWeight: 'unset' }}>Saisie manuelle</Divider>
                <FormItemNomOrganismeComplementaire
                  errorMessage="Veuillez saisir le nom de l'organisme complémentaire de la personne assurée"
                  initialValue={patient.couvertureSociale?.regimeComplementaire?.sNom || undefined}
                />
              </div>
              <FormItemNumeroAdherent
                errorMessage="Veuillez saisir le numéro d\'adhérent de l'assurée"
                initialValue={patient.couvertureSociale?.regimeComplementaire?.sNoAdherent}
              />
            </>
          ) : (
            <>
              <FormItemNomOrganismeComplementaire
                hidden
                required={false}
                initialValue={patient.couvertureSociale?.regimeComplementaire?.sNom || undefined}
              />
              <FormItemNumeroAdherent hidden required={false} initialValue={undefined} />
            </>
          )}
        </Form>
      </ContentCard>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton form="formSouscriptionMutuelle" /* form cancels centering style */ htmlType="submit" />
      </ActionsArea>
    </>
  );
}
