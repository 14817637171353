import { useLayoutEffect, useState } from 'react';

import {
  FormAccordPresenceAccompagnants,
  FormAccordPriseEnCharge,
  FormAccordTelesuivi,
  FormAccordTraitementDonneesSante,
  FormChartePriseEnChargePrestataireService,
  FormDecharge,
  FormDocAssuranceAutomobile,
  FormDocAssuranceImmeuble,
  FormDocCarnetDeSuivi,
  FormDocConsignesSecuriteOxygene,
  FormDocDeplacements,
  FormDocExtranetPatient,
  FormDocFormation,
  FormDocGestionDechets,
  FormDocImportantARretenir,
  FormDocInstallationElectrique,
  FormDocLettrePresentation,
  FormDocLivretNutrition,
  FormDocModaliteSchemaTherapeutique,
  FormDocPriseEnChargeFinanciere,
  FormDocTelesuivi,
  FormDocTraitementDonneesSantePersonnelles,
  FormInformationSecurite,
  FormRemiseMateriel,
  FormSignataire,
  FormSignature as FormConsentementSignature,
} from './Consentement';
import { FormAdresse, FormAssurance, FormContact, FormNaissance, FormNoms, FormVerificationIdentite } from './Patient/EtatCivil';
import { FormAssure, FormCaisseSecuriteSociale, FormNumeroSecuriteSociale } from './Patient/CouvertureSociale/RegimeObligatoire';
import { FormAdresseMutuelle, FormSouscriptionMutuelle } from './Patient/CouvertureSociale/RegimeComplementaire';
import { SectionEntourage, SectionReferents } from './Patient';
import {
  FormFormationAdapterConsommable,
  FormFormationAppareil,
  FormFormationBouteilleSecours,
  FormFormationBrancherDiffuseur,
  FormFormationPresenceGraviteDiffuseur,
  FormFormationCommentaires,
  FormFormationDecharge,
  FormFormationDesadapterConsommable,
  FormFormationDiffuseurs,
  FormFormationDivers,
  FormFormationEntretenir,
  FormFormationGererAlarmes,
  FormFormationMettreSousTension,
  FormFormationProgrammerPompe,
  FormFormationRemplirDiffuseur,
  FormFormationSignatures,
  FormFormationSurveillerDiffuseur,
  FormFormationTrierDechets,
  FormFormationUtiliserConsommables,
  FormFormationUtiliserConsommablesPerfusion,
  FormFormationUtiliserMaterielSecours,
  FormFormationUtiliserPerfuseur,
  FormFormationVoieAbord,
} from './Formation';
import SectionPersonnesFormees from './Formation/PersonnesFormees';
import { FormDateLieuSignature, FormSignature } from './Signature';

import type { default as ContratPret, Activite } from '../../interfaces/ContratPret';
import type AppPath from '../../interfaces/AppPath';

type FormComponent =
  | typeof FormSignataire
  | typeof FormRemiseMateriel
  | typeof FormChartePriseEnChargePrestataireService
  | typeof FormConsentementSignature
  | typeof FormDocAssuranceAutomobile
  | typeof FormDocAssuranceImmeuble
  | typeof FormDocCarnetDeSuivi
  | typeof FormDocConsignesSecuriteOxygene
  | typeof FormDocDeplacements
  | typeof FormDocExtranetPatient
  | typeof FormDocFormation
  | typeof FormDocGestionDechets
  | typeof FormDocImportantARretenir
  | typeof FormDocInstallationElectrique
  | typeof FormDocLettrePresentation
  | typeof FormDocLivretNutrition
  | typeof FormDocModaliteSchemaTherapeutique
  | typeof FormDocPriseEnChargeFinanciere
  | typeof FormDocTelesuivi
  | typeof FormDocTraitementDonneesSantePersonnelles
  | typeof FormAccordPresenceAccompagnants
  | typeof FormAccordPriseEnCharge
  | typeof FormAccordTelesuivi
  | typeof FormAccordTraitementDonneesSante
  | typeof FormDecharge
  | typeof FormInformationSecurite
  | typeof FormAdresse
  | typeof FormAssurance
  | typeof FormContact
  | typeof FormNaissance
  | typeof FormNoms
  | typeof FormVerificationIdentite
  | typeof FormAssure
  | typeof FormCaisseSecuriteSociale
  | typeof FormNumeroSecuriteSociale
  | typeof FormAdresseMutuelle
  | typeof FormSouscriptionMutuelle
  | typeof SectionEntourage
  | typeof SectionReferents
  | typeof FormFormationAdapterConsommable
  | typeof FormFormationAppareil
  | typeof FormFormationBouteilleSecours
  | typeof FormFormationBrancherDiffuseur
  | typeof FormFormationCommentaires
  | typeof FormFormationDecharge
  | typeof FormFormationDesadapterConsommable
  | typeof FormFormationDiffuseurs
  | typeof FormFormationDivers
  | typeof FormFormationEntretenir
  | typeof FormFormationGererAlarmes
  | typeof FormFormationMettreSousTension
  | typeof FormFormationProgrammerPompe
  | typeof FormFormationRemplirDiffuseur
  | typeof FormFormationSignatures
  | typeof FormFormationSurveillerDiffuseur
  | typeof FormFormationTrierDechets
  | typeof FormFormationUtiliserConsommables
  | typeof FormFormationUtiliserConsommablesPerfusion
  | typeof FormFormationUtiliserMaterielSecours
  | typeof FormFormationUtiliserPerfuseur
  | typeof FormFormationVoieAbord
  | typeof SectionPersonnesFormees
  | typeof FormDateLieuSignature
  | typeof FormSignature
  | typeof FormFormationPresenceGraviteDiffuseur;

/***
 * Note technique
 * Pour ce projet l'oxygène est dissocié du réspiratoire.
 * L'intervenant devra cocher R + O si besoin
 */

interface RouteConfig {
  Component: FormComponent;
  path: AppPath;
  section: string;
  subSection?: string;
  title?: string;
}

function getSubSectionSignataire(_: ContratPret): RouteConfig[] {
  return [
    {
      Component: FormSignataire,
      section: 'Consentement du patient',
      subSection: 'Signataire',
      path: '/contrat/consentement/signataire',
    },
  ];
}

function getSubSectionRemiseMateriel(_: ContratPret): RouteConfig[] {
  return [
    {
      Component: FormRemiseMateriel,
      section: 'Consentement du patient',
      subSection: 'Remise du matériel',
      path: '/contrat/consentement/materiel',
    },
  ];
}

function getSubSectionRemiseDocuments(contrat: ContratPret): RouteConfig[] {
  return [
    {
      Component: FormChartePriseEnChargePrestataireService,
      section: 'Consentement du patient',
      subSection: 'Remise des documents',
      path: '/contrat/consentement/documents',
    },
    {
      Component: FormDocLettrePresentation,
      section: 'Consentement du patient',
      subSection: 'Remise des documents',
      path: '/contrat/consentement/documents/lettre-presentation',
    },
    {
      Component: FormDocImportantARretenir,
      section: 'Consentement du patient',
      subSection: 'Remise des documents',
      path: '/contrat/consentement/documents/important-a-retenir',
    },
    {
      Component: FormDocPriseEnChargeFinanciere,
      section: 'Consentement du patient',
      subSection: 'Remise des documents',
      path: '/contrat/consentement/documents/prise-en-charge-financiere',
    },
    {
      Component: FormDocTraitementDonneesSantePersonnelles,
      section: 'Consentement du patient',
      subSection: 'Remise des documents',
      path: '/contrat/consentement/documents/traitement-donnees-personnelles-sante',
    },
    {
      Component: FormDocCarnetDeSuivi,
      section: 'Consentement du patient',
      subSection: 'Remise des documents',
      path: '/contrat/consentement/documents/carnet-de-suivi',
    },
    ...(['N', 'O', 'P', 'R'].some((activite) => contrat.activites.includes(activite as Activite))
      ? [
          {
            Component: FormDocInstallationElectrique,
            section: 'Consentement du patient',
            subSection: 'Remise des documents',
            path: '/contrat/consentement/documents/installation-electrique',
          } as RouteConfig,
        ]
      : []),
    ...(contrat.activites.includes('O')
      ? [
          {
            Component: FormDocConsignesSecuriteOxygene,
            section: 'Consentement du patient',
            subSection: 'Remise des documents',
            path: '/contrat/consentement/documents/consignes-securite-oxygene',
          } as RouteConfig,
          {
            Component: FormDocAssuranceImmeuble,
            section: 'Consentement du patient',
            subSection: 'Remise des documents',
            path: '/contrat/consentement/documents/assurance-immeuble',
          } as RouteConfig,
          {
            Component: FormDocAssuranceAutomobile,
            section: 'Consentement du patient',
            subSection: 'Remise des documents',
            path: '/contrat/consentement/documents/assurance-automobile',
          } as RouteConfig,
        ]
      : []),
    ...(contrat.activites.includes('N')
      ? [
          {
            Component: FormDocLivretNutrition,
            section: 'Consentement du patient',
            subSection: 'Remise des documents',
            path: '/contrat/consentement/documents/livret-nutrition-enterale',
          } as RouteConfig,
        ]
      : []),
    {
      Component: FormDocModaliteSchemaTherapeutique,
      section: 'Consentement du patient',
      subSection: 'Remise des documents',
      path: '/contrat/consentement/documents/modalites-schema-therapeutique',
    },
    {
      Component: FormDocDeplacements,
      section: 'Consentement du patient',
      subSection: 'Remise des documents',
      path: '/contrat/consentement/documents/deplacements',
    },
    {
      Component: FormDocGestionDechets,
      section: 'Consentement du patient',
      subSection: 'Remise des documents',
      path: '/contrat/consentement/documents/gestion-dechets',
    },
    ...(contrat.activites.includes('R')
      ? [
          {
            Component: FormDocTelesuivi,
            section: 'Consentement du patient',
            subSection: 'Remise des documents',
            path: '/contrat/consentement/documents/donnees-telesuivi',
          } as RouteConfig,
        ]
      : []),
    {
      Component: FormDocFormation,
      section: 'Consentement du patient',
      subSection: 'Remise des documents',
      path: '/contrat/consentement/documents/formation-evaluation',
    },
    {
      Component: FormDocExtranetPatient,
      section: 'Consentement du patient',
      subSection: 'Remise des documents',
      path: '/contrat/consentement/documents/extranet-patient',
    },
  ];
}

function getSubSectionPriseEnCharge(_: ContratPret): RouteConfig[] {
  return [
    {
      Component: FormAccordPresenceAccompagnants,
      section: 'Consentement du patient',
      subSection: 'Acceptation du patient',
      path: '/contrat/consentement/acceptations/presence-accompagnants',
    },
  ];
}

function getSubSectionDonnees(contrat: ContratPret): RouteConfig[] {
  return [
    {
      Component: FormAccordTraitementDonneesSante,
      section: 'Consentement du patient',
      subSection: 'Acceptation du patient',
      path: '/contrat/consentement/acceptations/donnees',
    },
    ...(contrat.consentement?.accords?.bTraitementDonneesSante && contrat.activites.includes('R')
      ? [
          {
            Component: FormAccordTelesuivi,
            section: 'Consentement du patient',
            subSection: 'Acceptation du patient',
            path: '/contrat/consentement/acceptations/telesuivi',
          } as RouteConfig,
        ]
      : []),
  ];
}

function getSubSectionDecharge(contrat: ContratPret): RouteConfig[] {
  return ['N', 'O', 'P', 'R'].some((activite) => contrat.activites.includes(activite as Activite))
    ? [
        {
          Component: FormDecharge,
          section: 'Consentement du patient',
          subSection: 'Décharge',
          path: '/contrat/consentement/decharge',
        },
      ]
    : [];
}

function getSubSectionInformation(_: ContratPret): RouteConfig[] {
  return [
    {
      Component: FormInformationSecurite,
      section: 'Consentement du patient',
      subSection: 'Informations',
      path: '/contrat/consentement/information',
    },
    {
      Component: FormAccordPriseEnCharge,
      section: 'Consentement du patient',
      subSection: 'Acceptation du patient',
      path: '/contrat/consentement/acceptations/prise-en-charge',
    },
  ];
}

function getSectionConsentement(contrat: ContratPret): RouteConfig[] {
  return [
    ...getSubSectionSignataire(contrat),
    ...getSubSectionRemiseMateriel(contrat),
    ...getSubSectionRemiseDocuments(contrat),
    ...getSubSectionPriseEnCharge(contrat),
    ...getSubSectionDonnees(contrat),
    ...getSubSectionDecharge(contrat),
    ...getSubSectionInformation(contrat),
    {
      Component: FormDateLieuSignature,
      section: 'Consentement du patient',
      subSection: 'Signature',
      path: '/contrat/consentement/date-lieu',
    },
    {
      Component: FormConsentementSignature,
      section: 'Consentement du patient',
      subSection: 'Signature',
      path: '/contrat/consentement/signature',
    },
  ];
}

function getSubSectionEtatCivil(_: ContratPret): RouteConfig[] {
  return [
    {
      Component: FormNoms,
      section: 'Renseignements patient',
      subSection: 'Etat civil et coordonnées',
      path: '/contrat/patient/etat-civil/noms',
    },
    {
      Component: FormNaissance,
      section: 'Renseignements patient',
      subSection: 'Etat civil et coordonnées',
      path: '/contrat/patient/etat-civil/date-naissance',
    },
    {
      Component: FormAdresse,
      section: 'Renseignements patient',
      subSection: 'Etat civil et coordonnées',
      path: '/contrat/patient/etat-civil/adresse',
    },
    {
      Component: FormContact,
      section: 'Renseignements patient',
      subSection: 'Etat civil et coordonnées',
      path: '/contrat/patient/etat-civil/contact',
    },
    {
      Component: FormVerificationIdentite,
      section: 'Renseignements patient',
      subSection: 'Etat civil et coordonnées',
      path: '/contrat/patient/etat-civil/verification-identite',
    },
    {
      Component: FormAssurance,
      section: 'Renseignements patient',
      subSection: 'Etat civil et coordonnées',
      path: '/contrat/patient/etat-civil/assurance',
    },
  ];
}

function getSubSectionCouvertureSociale(_: ContratPret): RouteConfig[] {
  return [
    {
      Component: FormAssure,
      section: 'Renseignements patient',
      subSection: 'Couverture sociale',
      path: '/contrat/patient/couverture-sociale',
    },
  ];
}

function getSubSectionRegimeObligatoire(_: ContratPret): RouteConfig[] {
  return [
    {
      Component: FormNumeroSecuriteSociale,
      section: 'Renseignements patient',
      subSection: 'Couverture sociale',
      path: '/contrat/patient/couverture-sociale/regime-obligatoire',
    },
    {
      Component: FormCaisseSecuriteSociale,
      section: 'Renseignements patient',
      subSection: 'Couverture sociale',
      path: '/contrat/patient/couverture-sociale/regime-obligatoire/CPAM',
    },
  ];
}

function getSubSectionRegimeComplementaire(contrat: ContratPret): RouteConfig[] {
  const FormSouscriptionMutuelleRouteConfig: RouteConfig = {
    Component: FormSouscriptionMutuelle,
    section: 'Renseignements patient',
    subSection: 'Couverture sociale',
    path: '/contrat/patient/couverture-sociale/mutuelle',
  };

  return contrat?.patient?.couvertureSociale?.regimeObligatoire?.bPasMutuelle
    ? [FormSouscriptionMutuelleRouteConfig]
    : [
        FormSouscriptionMutuelleRouteConfig,
        {
          Component: FormAdresseMutuelle,
          section: 'Renseignements patient',
          subSection: 'Couverture sociale',
          path: '/contrat/patient/couverture-sociale/mutuelle/adresse',
        },
      ];
}

function getSubSectionEntourage(_: ContratPret): RouteConfig[] {
  return [
    {
      Component: SectionEntourage,
      section: 'Renseignements patient',
      subSection: 'Entourage',
      path: '/contrat/patient/entourage',
    },
  ];
}

function getSubSectionReferents(_: ContratPret): RouteConfig[] {
  return [
    {
      Component: SectionReferents,
      section: 'Renseignements patient',
      subSection: 'Référents',
      path: '/contrat/patient/referents',
    },
  ];
}

function getSectionPatient(contrat: ContratPret): RouteConfig[] {
  return [
    ...getSubSectionEtatCivil(contrat),
    ...getSubSectionCouvertureSociale(contrat),
    ...getSubSectionRegimeObligatoire(contrat),
    ...getSubSectionRegimeComplementaire(contrat),
    ...getSubSectionEntourage(contrat),
    ...getSubSectionReferents(contrat),
  ];
}

function getSubSectionAppareils(contrat: ContratPret): RouteConfig[] {
  return [
    {
      Component: SectionPersonnesFormees,
      section: 'Formation et évaluation',
      subSection: 'Personne(s) formée(s)',
      path: '/contrat/formation/personnes-formees',
    },
    {
      Component: FormFormationAppareil,
      section: 'Formation et évaluation',
      subSection: 'Appareil(s)',
      path: '/contrat/formation/appareils',
    },
    ...(!contrat?.formation?.bAppareilsNonConcerne
      ? [
          {
            Component: FormFormationMettreSousTension,
            section: 'Formation et évaluation',
            subSection: 'Appareil(s)',
            path: '/contrat/formation/appareils/mettre-sous-tension',
          } as RouteConfig,
          {
            Component: FormFormationAdapterConsommable,
            section: 'Formation et évaluation',
            subSection: 'Appareil(s)',
            path: '/contrat/formation/appareils/adapter-consommable',
          } as RouteConfig,
          ...(['I', 'N', 'P'].some((activite) => contrat?.activites?.includes(activite as Activite))
            ? [
                {
                  Component: FormFormationProgrammerPompe,
                  section: 'Formation et évaluation',
                  subSection: 'Appareil(s)',
                  path: '/contrat/formation/appareils/programmer-pompe',
                } as RouteConfig,
              ]
            : []),
          {
            Component: FormFormationGererAlarmes,
            section: 'Formation et évaluation',
            subSection: 'Appareil(s)',
            path: '/contrat/formation/appareils/gerer-alarmes',
          } as RouteConfig,
          {
            Component: FormFormationDesadapterConsommable,
            section: 'Formation et évaluation',
            subSection: 'Appareil(s)',
            path: '/contrat/formation/appareils/desadapter-consommable',
          } as RouteConfig,
          {
            Component: FormFormationEntretenir,
            section: 'Formation et évaluation',
            subSection: 'Appareil(s)',
            path: '/contrat/formation/appareils/entretenir',
          } as RouteConfig,
          ...(contrat.activites.includes('R')
            ? [
                {
                  Component: FormFormationUtiliserMaterielSecours,
                  section: 'Formation et évaluation',
                  subSection: 'Appareil(s)',
                  path: '/contrat/formation/appareils/utiliser-materiel-secours',
                } as RouteConfig,
              ]
            : []),
        ]
      : []),
  ];
}

function getSubSectionConsommables(_: ContratPret): RouteConfig[] {
  return [
    {
      Component: FormFormationUtiliserConsommables,
      section: 'Formation et évaluation',
      subSection: 'Consommables',
      path: '/contrat/formation/consommables',
    },
  ];
}

function getSubSectionPerfusion(contrat: ContratPret): RouteConfig[] {
  if (!contrat?.activites.includes('P')) return [];

  return [
    {
      Component: FormFormationDiffuseurs,
      section: 'Formation et évaluation',
      subSection: 'Perfusion',
      path: '/contrat/formation/diffuseur',
    },
    ...(!contrat?.formation?.bDiffuseurNonConcerne
      ? [
          {
            Component: FormFormationRemplirDiffuseur,
            section: 'Formation et évaluation',
            subSection: 'Perfusion',
            path: '/contrat/formation/diffuseur/remplir',
          } as RouteConfig,
          {
            Component: FormFormationBrancherDiffuseur,
            section: 'Formation et évaluation',
            subSection: 'Perfusion',
            path: '/contrat/formation/diffuseur/brancher',
          } as RouteConfig,
        ]
      : []),
    {
      Component: FormFormationPresenceGraviteDiffuseur,
      section: 'Formation et évaluation',
      subSection: 'Perfusion',
      path: '/contrat/formation/diffuseur/gravite',
    },
    {
      Component: FormFormationSurveillerDiffuseur,
      section: 'Formation et évaluation',
      subSection: 'Perfusion',
      path: '/contrat/formation/diffuseur/surveiller',
    },
    {
      Component: FormFormationUtiliserPerfuseur,
      section: 'Formation et évaluation',
      subSection: 'Perfusion',
      path: '/contrat/formation/perfuseur/utiliser',
    },
    {
      Component: FormFormationVoieAbord,
      section: 'Formation et évaluation',
      subSection: 'Perfusion',
      path: '/contrat/formation/voie-d-abord',
    },
    {
      Component: FormFormationUtiliserConsommablesPerfusion,
      section: 'Formation et évaluation',
      subSection: 'Perfusion',
      path: '/contrat/formation/voie-d-abord/utiliser-consommables',
    },
    {
      Component: FormFormationTrierDechets,
      section: 'Formation et évaluation',
      subSection: 'Perfusion',
      path: '/contrat/formation/cosmolys',
    },
  ];
}

function getSectionFormation(contrat: ContratPret): RouteConfig[] {
  return [
    ...getSubSectionAppareils(contrat),
    ...getSubSectionConsommables(contrat),
    ...getSubSectionPerfusion(contrat),
    {
      Component: FormFormationDivers,
      section: 'Formation et évaluation',
      subSection: 'Divers',
      path: '/contrat/formation/divers',
    },
    ...(contrat.activites.includes('O')
      ? [
          {
            Component: FormFormationBouteilleSecours,
            section: 'Formation et évaluation',
            subSection: 'Oxygénothérapie',
            path: '/contrat/formation/bouteille-secours',
          } as RouteConfig,
        ]
      : []),
    {
      Component: FormFormationCommentaires,
      section: 'Formation et évaluation',
      subSection: 'Commentaires',
      path: '/contrat/formation/commentaires',
    },
    ...(contrat.formation?.personnesFormees?.some((pf) => pf.bLiberal)
      ? [
          {
            Component: FormFormationDecharge,
            section: 'Formation et évaluation',
            subSection: 'Décharge',
            path: '/contrat/formation/decharge',
          } as RouteConfig,
        ]
      : []),
    {
      Component: FormFormationSignatures,
      section: 'Formation et évaluation',
      subSection: 'Signature',
      path: `/contrat/formation/signatures`,
    },
  ];
}

function getSectionSignature(_: ContratPret): RouteConfig[] {
  return [
    {
      Component: FormDateLieuSignature,
      section: 'Signature',
      subSection: undefined,
      path: '/contrat/signature',
    },
    {
      Component: FormSignature,
      section: 'Signature',
      subSection: 'Intervenant',
      path: '/contrat/signature/signature',
    },
  ];
}

// function getContratInsulinotherapieRoutes() { }
// function getContratNutritionRoutes() { }
// function getContratOxygenotherapieRoutes() { }
// function getContratPerfusionRoutes() { }
// function getContratRespiratoireRoutes() { }

export function getContratRoutes(contrat: ContratPret): RouteConfig[] {
  return [...getSectionPatient(contrat), ...getSectionConsentement(contrat), ...getSectionFormation(contrat), ...getSectionSignature(contrat)];
}

export function getNextRouteConfig(currentUrl: string, contrat: ContratPret) {
  const nextRoutesConfig = getContratRoutes(contrat);
  return nextRoutesConfig[nextRoutesConfig.findIndex((route) => route.path === currentUrl) + 1];
}

export function useContratRoutes(contrat: ContratPret) {
  const [routesConfig, setRoutesConfig] = useState<RouteConfig[]>([]);

  // const getCurrentRouteConfig = () => {}
  // const getNextUrl = () => {}
  // const getPreviousUrl = () => {}

  useLayoutEffect(() => setRoutesConfig(getContratRoutes(contrat)), [contrat]);

  return routesConfig;
}
