import { default as styled, keyframes } from "styled-components";
import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import type { RouteComponentProps } from "react-router-dom";

import { default as View, Content } from "../../components/View";
import ContentCard from "../../components/ContentCard";
import EpiSantelys from "./EpiSantelys.png";

const StartButton = styled(Button)`
  background-color: #f0d80f;
  border: none;
  color: #044493;

  :active,
  :focus,
  :hover {
    background-color: #f0d80f;
    color: #044493;
  }
`;

const AppTitle = styled.h1`
  animation: ${keyframes`to { transform: translate(0, 0); }`} 1s forwards;
  color: #fadb05;
  font-size: 3em;
  position: relative;
  text-align: center;
  text-shadow: #044493 0px 5px 5px;
  top: 25%;
  transform: translate(0, -100%);
`;

const AppTitleArea = styled.div`
  height: 50vh;
  position: absolute;
  top: 0;
`;

const Image = styled.img`
  animation: ${keyframes`to { transform: translate(0, 0) rotate(0); }`} 1.5s
    1.5s forwards;
  bottom: 0;
  height: 30vh;
  left: 0;
  position: absolute;
  transform: translate(-100%, 100%) rotate(45deg);
`;

const StartContentCard = styled(ContentCard)`
  align-items: center;
  animation: ${keyframes`to { opacity: 1; }`} 1s 1s forwards;
  display: flex;
  flex-direction: column;
  opacity: 0;
`;

const StartContentCardTitle = styled.h2`
  color: #044493;
  margin-bottom: 20px;
`;

const Version = styled.div`
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  text-align: center;
  color: #fadb05;
`;

export default function HomeView({ history }: RouteComponentProps) {
  return (
    <View>
      <AppTitleArea>
        <AppTitle>Recueil de Consentement</AppTitle>
      </AppTitleArea>

      <Content>
        <StartContentCard>
          <StartContentCardTitle>Bienvenue</StartContentCardTitle>

          <StartButton
            onClick={() => history.push("/contrat")}
            shape="round"
            size="large"
            type="primary"
          >
            Saisir un contrat <RightOutlined style={{ marginLeft: "8px" }} />
          </StartButton>
        </StartContentCard>
      </Content>

      <Image alt="Epi Santélys" src={EpiSantelys} />

      <Version>{process.env.REACT_APP_VERSION}</Version>
    </View>
  );
}
