import { FocusEvent } from 'react';
import { Input as AntdInput } from 'antd';
import type { InputProps } from 'antd';
import styled from 'styled-components';

const StyledInput = styled(AntdInput)`
  border-radius: 10px;

  .ant-input {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :focus-within {
    border-color: #044493;
  }
`;

export default function Input({ onBlur, ...props }: InputProps) {
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    e.target.scroll({ left: 0 });
    onBlur?.(e);
  };

  return <StyledInput {...props} onBlur={handleBlur} />;
}
