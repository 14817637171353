import { Form } from 'antd';
import Input from '../Input';

const maxLength = 1000; // TODO
const pattern = new RegExp('');
const placeholder = 'A1d2h3e4r5e6n7t8';
const defaultErrorMessage = 'Veuillez saisir un numéro d\'adhérent';
const defaultLabel = 'N° d\'adhérent';
const defaultName = 'sNoAdherent';

interface FormItemNumeroAdherentProps {
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
  hidden?: boolean;
}

export default function FormItemNumeroAdherent({ autoFocus, disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = true, hidden = false }: FormItemNumeroAdherentProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      hidden={hidden}
      label={label}
      name={name}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <Input
        allowClear
        autoFocus={autoFocus}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        size="large"
      />
    </Form.Item>
  );
}
