import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { Auth, Identite } from "../../interfaces/Identite";
import type { Store } from "../../interfaces/Store";

const initialState: Auth = {
  bConnecte: false,
  identite: undefined,
  sErreur: undefined,
};

export const slice = createSlice({
  name: "utilisateur",
  initialState,
  reducers: {
    connexionErreur: (
      state,
      { payload }: PayloadAction<{ sErreur?: string }>
    ) => {
      state.bConnecte = false;
      state.identite = initialState.identite;
      state.sErreur = payload.sErreur;
    },
    connexionSucces: (
      state,
      { payload }: PayloadAction<{ user: Identite; token: string }>
    ) => {
      state.bConnecte = true;
      state.identite = payload.user;
      state.sErreur = undefined;
    },
    deconnexionSucces: (state) => {
      state.bConnecte = false;
      state.identite = initialState.identite;
      state.sErreur = undefined;
    },
  },
});

export const { connexionSucces, connexionErreur, deconnexionSucces } =
  slice.actions;
export const selectStoreErreur = (state: Store) => state.utilisateur.sErreur;
export const selectUtilisateur = (state: Store) => state.utilisateur.identite;

export default slice.reducer;
