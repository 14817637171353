// import { Button } from 'antd';
import { Layout } from 'antd';
import styled from 'styled-components';

// const ActionsWrapper = styled.div`
//   border: solid black;
//   bottom: 0;
//   display: flex;
//   justify-content: space-evenly;
//   position: fixed;
//   width: 100vw;
// `;

// const ActionButton = styled(Button)`
//   width: 40%;
// `;

// interface FooterProps {
//   hasPreviousButton?: boolean;
//   onNextClick: () => void;
//   onPreviousClick: () => void;
// }

// export default function Footer({ hasPreviousButton, onNextClick, onPreviousClick }: FooterProps) {
//   return (
//     <ActionsWrapper>
//       {hasPreviousButton && <ActionButton
//         onClick={onPreviousClick}
//         shape="round"
//       >
//         Précédent
//       </ActionButton>}

//       <ActionButton
//         onClick={onNextClick}
//         shape="round"
//         type="primary"
//       >
//         Suivant
//       </ActionButton>
//     </ActionsWrapper>
//   );
// }

export default styled(Layout.Footer)`
  background: transparent;
  height: 65px;
`;
