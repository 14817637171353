import styled from 'styled-components';

export default styled.iframe`
  border: 0;

   // FIXME: temporary visual improvement while iframe background is not transparent
  border-radius: 20px;

  height: 100%;
  width: 100%;
`;
