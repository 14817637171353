import { Input } from 'antd';
import type { TextAreaProps } from 'antd/lib/input';
import styled from 'styled-components';

const TextAreaWrapper = styled.div`
  .ant-input, .ant-input-affix-wrapper  {
    border-radius: 10px;

    :focus {
      border-color: #044493;
    }
  }
`;

export default function TextArea(props: any) {
  return (
    <TextAreaWrapper>
      <Input.TextArea {...props} />
    </TextAreaWrapper>
  );
}
