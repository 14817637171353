import { Button, Divider, Form } from 'antd';
import { UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { ComponentProps, useEffect } from 'react';
import { useState } from 'react';

import { ActionsArea, BackButton, NextButton } from '../../../../components/buttons';
import type { AutrePersonneFormee, Entourage, Formation, Patient, Referent } from '../../../../interfaces/ContratPret';
import {
  FormItemAdresse,
  FormItemBatiment,
  FormItemCodePostal,
  FormItemCodeTypeVoie,
  FormItemCommune,
  FormItemEtage,
  FormItemGSM,
  FormItemInterphone,
  FormItemLienAvecPatient,
  FormItemNom,
  FormItemNumeroVoie,
  FormItemPorte,
  FormItemPrenom,
  FormItemTelephoneFixe,
} from '../../../../components/formItems';
import ContentCard from '../../../../components/ContentCard';

function AddButton(props: ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      icon={<UserAddOutlined />}
      shape="round"
      size="large"
      style={{ backgroundColor: '#f0d80f', border: 'none', color: '#044493', width: '100%' }}
      type="primary"
    >
      Ajouter à la formation
    </Button>
  );
}

function RemoveButton(props: ComponentProps<typeof Button>) {
  return (
    <Button {...props} danger icon={<UserDeleteOutlined />} shape="round" size="large" style={{ width: '100%' }} type="primary">
      Retirer de la formation
    </Button>
  );
}

interface FormAutreProps {
  onAdd?: (newPerson: AutrePersonneFormee) => void;
  onChange?: (updatedPerson: Partial<AutrePersonneFormee>) => void;
  onRemove?: () => void;
  personne?: AutrePersonneFormee;
}

function FormAutre({ onAdd, onChange, onRemove, personne }: FormAutreProps) {
  const [form] = Form.useForm();

  const handleSubmit = ({ sTelFixe, sTelGsm, ...rest }: AutrePersonneFormee) => {
    form.resetFields();
    onAdd?.({
      ...rest,
      sTelFixe: sTelFixe?.replace(/\s/g, ''),
      sTelGsm: sTelGsm?.replace(/\s/g, ''),
    });
  };

  return (
    <ContentCard style={{ marginBottom: '20px' }}>
      <Form form={form} onFinish={handleSubmit} onValuesChange={onChange}>
        <FormItemNom disabled={personne?.bSupprime} initialValue={personne?.sNom} />

        <FormItemPrenom disabled={personne?.bSupprime} initialValue={personne?.sPrenom} />

        <FormItemLienAvecPatient disabled={personne?.bSupprime} initialValue={personne?.sTypeLien} />

        <div style={{ alignItems: 'start', display: 'grid', gridGap: '20px', gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <FormItemNumeroVoie disabled={personne?.bSupprime} initialValue={personne?.sNumeroVoie} />

          <FormItemCodeTypeVoie disabled={personne?.bSupprime} initialValue={personne?.sCodeTypeVoie} />
        </div>

        <FormItemAdresse disabled={personne?.bSupprime} initialValue={personne?.sAdresse} />

        <div style={{ display: 'grid', gridColumnGap: '20px', gridTemplateColumns: 'repeat(2, 1fr)' }}>
          <FormItemBatiment disabled={personne?.bSupprime} initialValue={personne?.sBatiment} />

          <FormItemEtage disabled={personne?.bSupprime} initialValue={personne?.sEtage} />

          <FormItemPorte disabled={personne?.bSupprime} initialValue={personne?.sPorte} />

          <FormItemInterphone disabled={personne?.bSupprime} initialValue={personne?.sInterphone} />
        </div>

        <FormItemCodePostal disabled={personne?.bSupprime} initialValue={personne?.sCodePostal} />

        <FormItemCommune disabled={personne?.bSupprime} initialValue={personne?.sVille} />

        <FormItemGSM disabled={personne?.bSupprime} initialValue={personne?.sTelGsm} />

        <FormItemTelephoneFixe disabled={personne?.bSupprime} initialValue={personne?.sTelFixe} />

        <Form.Item style={{ margin: '10px 0 0 0' }}>{personne ? <RemoveButton onClick={onRemove} /> : <AddButton htmlType="submit" />}</Form.Item>
      </Form>
    </ContentCard>
  );
}

interface EntourageCardProps {
  bSelected: boolean;
  onClick: () => void;
  sNom: string;
  sPrenom: string;
  sTypeLien: string;
}

function EntourageCard({ bSelected, onClick, sNom, sPrenom, sTypeLien }: EntourageCardProps) {
  return (
    <ContentCard dark={!bSelected} style={{ marginBottom: '15px' }}>
      <div style={{ marginBottom: '15px', textAlign: 'center' }}>
        {`${sNom?.toUpperCase()} ${sPrenom} `}
        <span style={{ fontSize: 'smaller' }}>{`(${sTypeLien?.toLowerCase()})`}</span>
      </div>

      {bSelected ? <RemoveButton onClick={onClick} /> : <AddButton onClick={onClick} />}
    </ContentCard>
  );
}

interface ReferentCardProps {
  bSelected: boolean;
  onClick: () => void;
  sNom: string;
  sPrenom: string;
  sTypeTiers: string;
}

function ReferentCard({ bSelected, onClick, sNom, sPrenom, sTypeTiers }: ReferentCardProps) {
  return (
    <ContentCard dark={!bSelected} style={{ marginBottom: '15px' }}>
      <div style={{ marginBottom: '15px', textAlign: 'center' }}>
        {`${sNom?.toUpperCase()} ${sPrenom ? `${sPrenom} ` : ''}`}
        <span style={{ fontSize: 'smaller' }}>{`(${sTypeTiers?.toLowerCase()})`}</span>
      </div>

      {bSelected ? <RemoveButton onClick={onClick} /> : <AddButton onClick={onClick} />}
    </ContentCard>
  );
}

interface SectionPersonnesFormeesProps {
  formation?: Formation;
  onPreviousClick: () => void;
  onSubmit: ({ formation }: { formation: Formation }) => void;
  patient: Patient;
}

const shallowEqual = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
};

export default function SectionPersonnesFormees({ formation, onPreviousClick, onSubmit, patient }: SectionPersonnesFormeesProps) {
  const [otherPeople, setOtherPeople] = useState<AutrePersonneFormee[]>(
    formation?.personnesFormees?.filter((pf) =>
      ![...patient.entourage, ...patient.referents, patient.etatCivil].map((p) => shallowEqual(p, pf))
    ) || []
  );
  const [selectedPeople, setSelectedPeople] = useState<(Entourage | Referent | Patient['etatCivil'])[]>(
    formation?.personnesFormees?.filter((pf) =>
      [...patient.entourage, ...patient.referents, patient.etatCivil].map((p) => shallowEqual(p, pf))
    ) || []
  );

  const addNewPerson = (newPerson: AutrePersonneFormee) => {
    const elem = document.getElementById('scrollView');
    elem?.scrollTo({ top: 0 });
    setOtherPeople((otherPeople) => [...otherPeople, newPerson]);
  };

  const handlePersonUpdate = (idx: number, updatedData: Partial<AutrePersonneFormee>) =>
    setSelectedPeople((people) => {
      people.splice(idx, 1, { ...people[idx], ...updatedData });
      return [...people];
    });

  const handleSubmit = () =>
    onSubmit({
      formation: { ...formation, personnesFormees: [...otherPeople, ...selectedPeople] },
    });

  const switchSelection = (person: Entourage | Referent | Patient['etatCivil']) => {
    const others = selectedPeople.filter((p) => p.sNom !== person.sNom || p.sPrenom !== person.sPrenom);
    setSelectedPeople((previousOnes) => (others.length === previousOnes.length ? [...previousOnes, person] : others));
  };

  useEffect(() => {
    selectedPeople.length === 0 && switchSelection(patient.etatCivil || null);
  }, []);

  return (
    <>
      <div id="scrollView" style={{ height: '100%', overflowY: 'scroll', scrollbarWidth: 'none', width: '100%' }}>
        <Divider style={{ borderColor: 'white', color: 'white' }}>Patient</Divider>
        <ReferentCard
          bSelected={selectedPeople.some((p) => p.sNom === patient.etatCivil?.sNom && p.sPrenom === patient.etatCivil?.sPrenom)}
          key={`patient-0`}
          onClick={() => switchSelection(patient.etatCivil || null)}
          sNom={patient.etatCivil?.sNom!}
          sPrenom={patient.etatCivil?.sPrenom!}
          sTypeTiers={'patient'}
        />
        <Divider style={{ borderColor: 'white', color: 'white' }}>Référents</Divider>
        {patient.referents
          .filter((r) => !r.bSupprime)
          .map((referent, i) => (
            <ReferentCard
              bSelected={selectedPeople.some((p) => p.sNom === referent.sNom && p.sPrenom === referent.sPrenom)}
              key={`referent-${i}`}
              onClick={() => switchSelection(referent)}
              sNom={referent.sNom!}
              sPrenom={referent.sPrenom!}
              sTypeTiers={referent.sTypeTiers!}
            />
          ))}

        <Divider style={{ borderColor: 'white', color: 'white' }}>Entourage</Divider>

        {patient.entourage
          .filter((p) => !p.bSupprime)
          .map((proche, i) => (
            <EntourageCard
              bSelected={selectedPeople.some((p) => p.sNom === proche.sNom && p.sPrenom === proche.sPrenom)}
              key={`entourage-${i}`}
              onClick={() => switchSelection(proche)}
              sNom={proche.sNom!}
              sPrenom={proche.sPrenom!}
              sTypeLien={proche.sTypeLien!}
            />
          ))}

        <Divider style={{ borderColor: 'white', color: 'white' }}>Autre</Divider>

        {otherPeople.map((person, i) => (
          <FormAutre
            key={`autre-${i}`}
            onChange={(updatedPerson) => handlePersonUpdate(i, updatedPerson)}
            onRemove={() => setOtherPeople(otherPeople.filter((_, j) => i !== j))}
            personne={person}
          />
        ))}

        <FormAutre onAdd={addNewPerson} />
      </div>

      <ActionsArea>
        <BackButton onClick={onPreviousClick} />
        <NextButton disabled={![...otherPeople, ...selectedPeople].length} onClick={handleSubmit} />
      </ActionsArea>
    </>
  );
}
