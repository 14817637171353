import {
  combineReducers, configureStore, getDefaultMiddleware
} from '@reduxjs/toolkit';
// import logger from 'redux-logger';

import utilisateurReducer from './redux/utilisateur/utilisateur.slice';

const middleware = [...getDefaultMiddleware()];
const reducer = combineReducers({
  utilisateur: utilisateurReducer
});

export default configureStore({
  middleware, reducer
});
