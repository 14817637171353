import type { Action } from "@reduxjs/toolkit";
import type { ThunkAction } from "redux-thunk";
import { verify } from "jsonwebtoken";
import axios from "axios";

import { connexionErreur, connexionSucces } from "../utilisateur.slice";
import type { Identite } from "../../../interfaces/Identite";
import config from "../../../appConfig";

export interface Response {
  user: Identite;
  cookie: string;
}

export function connexionAction(
  token: string
): ThunkAction<void, Identite, unknown, Action<string>> {
  return async (dispatch) => {
    if (!token) {
      dispatch<any>(connexionErreur({ sErreur: "Token non trouvé" }));
    } else {
      try {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        const response = await axios(
          `${config.identityServerURL}/api/auth/verify`,
          {
            method: "POST",
            data: {},
            withCredentials: true,
          }
        );

        const {
          data: { user },
        } = response;

        if (user) {
          document.cookie = `Authorization=${token};path=/;secure;SameSite=strict`;

          dispatch<any>(connexionSucces({ user, token }));
        }

        //-----
        // console.log(token);
        // const payload = verify(token, config.jwtPUBLIC!, {
        //   algorithms: ["RS512"],
        // });

        // document.cookie = `Authorization=${token};path=/;secure;SameSite=strict`;

        // axios.defaults.headers.common.Authorization = `Bearer ${token}`;

        // dispatch<any>(connexionSucces({ user: payload as Identite, token }));
        //----
      } catch (err: any) {
        document.cookie =
          "Authorization=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;secure;SameSite=strict";

        delete axios.defaults.headers.common.Authorization;
        console.log(err);
        dispatch<any>(connexionErreur({ sErreur: err.message }));
      }
    }
  };
}
