import { CheckOutlined, LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import type { ButtonProps } from 'antd';
import styled from 'styled-components';

export function ActionButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      shape="round"
      size="large"
      style={{ ...props.style, border: 'none', boxShadow: '#044493 0px 5px 5px', color: '#044493' }}
    />
  );
}

interface ActionsProps {
  onPreviousClick: () => void;
  onSubmit: (values: any) => void;
}

export function Actions({ onPreviousClick, onSubmit }: ActionsProps) {
  return (
    <ActionsArea>
      <BackButton onClick={onPreviousClick} />
      <NextButton onClick={() => onSubmit({})} />
    </ActionsArea>
  );
}

export const ActionsArea = styled.div`
  bottom: 20px;
  display: flex;
  justify-content: space-around;
  position: fixed;
  width: 100%;

  button:not(:only-child) {
    flex: 1 1 0;
    margin: 0 10px;
  }
`;

export function BackButton(props: ButtonProps) {
  return (
    <ActionButton
      {...props}
      icon={<LeftOutlined />}
      style={{ backgroundColor: '#e4efec' }}
    >
      Précédent
    </ActionButton>
  );
}

export function NextButton(props: ButtonProps) {
  return (
    <ActionButton
      {...props}
      icon={<CheckOutlined />}
      type="primary"
      style={{ backgroundColor: props.disabled ? '#f0d80f80' : '#f0d80f' }}
    >
      Suivant
    </ActionButton>
  );
}
