import type { ComponentProps } from 'react';
import { MenuFoldOutlined } from '@ant-design/icons';

import Drawer from '../../../components/Drawer';
import NavDrawerContent from './NavDrawerContent';
import config from '../../../appConfig';

const backgroundColor = '#edf3f4';

interface NavDrawerProps extends ComponentProps<typeof NavDrawerContent> {
  onClose: () => void;
  visible: boolean;
}

export default function NavDrawer({ onClose, visible, ...contentProps }: NavDrawerProps) {
  return (
    <Drawer
      bodyStyle={{ backgroundColor }}
      closeIcon={<MenuFoldOutlined />}
      footer={config.version}
      footerStyle={{ backgroundColor }}
      forceRender
      headerStyle={{ backgroundColor }}
      onClose={onClose}
      placement="left"
      title="Recueil de consentement"
      visible={visible}
      width="auto"
    >
      <NavDrawerContent {...contentProps} />
    </Drawer>
  );
}
