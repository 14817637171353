import { Button, Result as AntdResult } from "antd";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { deconnexionAction } from "../redux/utilisateur/actions/deconnexion.action";
import { default as View, Content } from "../components/View";
import ContentCard from "../components/ContentCard";
import IFrame from "./Login/IFrame";

import config from "../appConfig";

const Result = styled(AntdResult)`
  padding: 10% 0;
`;

const Link = styled(RouterLink)`
  color: white;

  :hover {
    color: white;
  }
`;

function HomeButton() {
  return (
    <Button shape="round" type="primary">
      <Link to="/">Retour à l'accueil</Link>
    </Button>
  );
}

function NotAllowedCard() {
  return (
    <ContentCard>
      <Result
        status={500}
        title="RCP sans visite SMART"
        subTitle="La saisie d'un recueil de consentement Patient doit être effectuée dans le cadre d'une visite SMART."
        extra={<HomeButton />}
      />
      <IFrame
        src={`${config.identityServerURL}/logout`}
        title="logout"
      ></IFrame>
    </ContentCard>
  );
}

const NotAllowedView = () => {
  const dispatch = useDispatch();
  dispatch<any>(deconnexionAction());

  window.onbeforeunload = () => {};

  return (
    <View>
      <Content>
        <NotAllowedCard />
      </Content>
    </View>
  );
};
export default NotAllowedView;
