import { Form } from 'antd';
import Input from '../Input';

const maxLength = 5;
const pattern = new RegExp('');
const placeholder = '12345';
const defaultErrorMessage = 'Veuillez saisir un code postal';
const defaultLabel = 'Code postal';
const defaultName = 'sCodePostal';

interface FormItemCodePostalProps {
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function FormItemCodePostal({ disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = false }: FormItemCodePostalProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <Input
        allowClear
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        size="large"
      />
    </Form.Item>
  );
}
