import { Drawer } from "antd";
import styled from "styled-components";

export default styled(Drawer)`
  --border-color: #5b98d1;

  button.ant-drawer-close {
    color: black;
  }

  div.ant-drawer-content {
    background-color: transparent;
  }

  div.ant-drawer-footer {
    border-top: solid 1px var(--border-color);
  }

  div.ant-drawer-header {
    border-bottom: solid 1px var(--border-color);
  }
`;
