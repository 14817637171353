import { Form } from 'antd';
import Input from '../Input';

const maxLength = 2;
const pattern = new RegExp('^(0[1-9])|([1-8]\\d)|(9[0-7])$');
const placeholder = '00';
const defaultErrorMessage = 'Veuillez saisir la clé du numéro de sécurité sociale';
const defaultLabel = 'Clé de contrôle';
const defaultName = 'sCleSecu';

interface FormItemSecuriteSocialeCleProps {
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function FormItemSecuriteSocialeCle({ autoFocus, disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = true }: FormItemSecuriteSocialeCleProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[
        { message: errorMessage, required },
        { message: 'Clé du numéro de sécurité sociale invalide', pattern } // TODO
      ]}
    >
      <Input
        allowClear
        autoFocus={autoFocus}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        size="large"
      />
    </Form.Item>
  );
}
