import { Form } from 'antd';
import Input from '../Input';

const maxLength = 18;
const pattern = new RegExp('^([123478])\\s?([0-9]{2})\\s?(0[1-9]|1[0-2]|3[1-9]|4[0-2]|[5-9][0-9]|20)\\s?([0][1-9]|2[AB]|[1-9][0-9])\\s?([0-9]{3})\\s?(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})$');
const placeholder = '0 00 00 00 000 000';
const defaultErrorMessage = 'Veuillez saisir un numéro de sécurité sociale';
const defaultLabel = 'N° de sécurité sociale';
const defaultName = 'sNumeroSecu';

interface FormItemSecuriteSocialeNumeroProps {
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

function normalize(numeroSecuriteSociale?: string) {
  return numeroSecuriteSociale?.replace(
    /^(\d)\s?(\d{0,2})\s?(\d{0,2})\s?(\d{0,2})\s?(\d{0,3})\s?(\d{0,3})$/g,
    '$1 $2 $3 $4 $5 $6'
  ).trim();
}

export default function FormItemSecuriteSocialeNumero({ autoFocus, disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = true }: FormItemSecuriteSocialeNumeroProps) {
  return (
    <Form.Item
      initialValue={normalize(initialValue)}
      label={label}
      name={name}
      normalize={normalize}
      rules={[
        { message: errorMessage, required },
        { message: 'Numéro de sécurité sociale invalide', pattern }
      ]}
    >
      <Input
        allowClear
        autoFocus={autoFocus}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        size="large"
      />
    </Form.Item>
  );
}
