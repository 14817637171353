import { Form } from 'antd';
import Input from '../Input';

const maxLength = 1000; // TODO
const pattern = new RegExp('');
const placeholder = 'Prénom';
const defaultErrorMessage = 'Veuillez saisir un prénom';
const defaultLabel = 'Prénom';
const defaultName = 'sPrenom';

interface FormItemPrenomProps {
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

function normalize(prenom: string) {
  return prenom.replace(
    /[a-zA-ZÀ-ÖØ-öø-ÿ]+/g,
    s => s[0].toUpperCase() + s.slice(1)
  );
}

export default function FormItemPrenom({ disabled, errorMessage = defaultErrorMessage, initialValue, label = defaultLabel, name = defaultName, required = true }: FormItemPrenomProps) {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      normalize={normalize}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <Input
        allowClear
        autoCapitalize="words"
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        size="large"
      />
    </Form.Item>
  );
}
