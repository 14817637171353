import { Form } from "antd";

import { default as Select, Option } from "../Select";
import { useConfig } from "../ConfigContext";

const pattern = new RegExp("");
const placeholder = "Sélectionner...";
const defaultErrorMessage = "Veuillez sélectionner un lien avec le patient";
const defaultLabel = "Lien avec le patient";
const defaultName = "sTypeLien";

interface FormItemLienAvecPatientProps {
  disabled?: boolean;
  errorMessage?: string;
  initialValue?: string;
  label?: string;
  name?: string;
  required?: boolean;
}

export default function FormItemLienAvecPatient({
  disabled,
  errorMessage = defaultErrorMessage,
  initialValue,
  label = defaultLabel,
  name = defaultName,
  required = false,
}: FormItemLienAvecPatientProps) {
  const config = useConfig();

  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[{ message: errorMessage, pattern, required }]}
    >
      <Select disabled={disabled} placeholder={placeholder} size="large">
        {config?.tTypesLien.map((typeLien) => (
          <Option key={typeLien.sCode} value={typeLien.sCode}>
            {typeLien.sLibelle}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}
