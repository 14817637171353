import type { ReactSignatureCanvasProps } from 'react-signature-canvas';
import { forwardRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import './SignatureInput.css';

type SignatureInputProps = Omit<ReactSignatureCanvasProps, 'canvasProps'>;

export default forwardRef<SignatureCanvas, SignatureInputProps>(
  (props, ref) => <SignatureCanvas
    {...props}
    backgroundColor='white'
    canvasProps={{ className: 'signaturePad' }}
    ref={ref}
  />
);
