import type { Action } from "@reduxjs/toolkit";
import type { ThunkAction } from "redux-thunk";
import axios from "axios";
import type { Utilisateur } from "../../../interfaces/Identite";
import { deconnexionSucces } from "../utilisateur.slice";

export function deconnexionAction(): ThunkAction<
  void,
  Utilisateur,
  unknown,
  Action<string>
> {
  return async (dispatch) => {
    document.cookie =
      "Authorization=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;secure;SameSite=strict";

    delete axios.defaults.headers.common.Authorization;

    dispatch<any>(deconnexionSucces());
  };
}
