import { cleanEnv, str, url } from 'envalid';

export default function validateEnv() {
  cleanEnv(process.env, {
    REACT_APP_IDENTITY_SERVER_URL: url(),
    REACT_APP_NAME: str(),
    REACT_APP_SERVER_URL: url(),
    REACT_APP_THEME: str(),
    REACT_APP_VERSION: str(),
    REACT_APP_JWT_PUBLIC: str()
  });
}
