import AppConfig from './interfaces/AppConfig';

const config: AppConfig = {
  identityServerURL: process.env.REACT_APP_IDENTITY_SERVER_URL,
  jwtPUBLIC: process.env.REACT_APP_JWT_PUBLIC,
  name: process.env.REACT_APP_NAME,
  serverURL: process.env.REACT_APP_SERVER_URL,
  theme: process.env.REACT_APP_THEME,
  version: process.env.REACT_APP_VERSION
};

export default config;
